import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import { darken, lighten, styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const columns = [
    { field: 'order_id', headerName: 'Order id', width: 90 },

    {
        field: 'full_name',
        headerName: 'Full Name/Client Name',
        width: 150,
    },

    {
        field: 'email',
        headerName: 'email',
        width: 150,
    },

    {
        field: 'ref_number',
        headerName: 'Reference Number',
        width: 150,
    },

    {
        field: 'remark',
        headerName: 'Remark',
        width: 150,
    },
    {
        field: 'state_str',
        headerName: 'State',
        width: 100,
    }, {
        field: 'quantity',
        headerName: 'Num of shirts',
        width: 100,
    }, {
        field: 'items',
        headerName: 'Order Items',
        width: 100,
    }, {
        field: 'order_time',
        headerName: 'Order time',
        width: 180,
    }, {
        field: 'usr_name',
        headerName: 'Tailor User Name',
        width: 180,
    },

];

const columnGroupingModel = [
    {
        groupId: 'Internal',
        description: '',
        children: [{ field: 'id' }],
    },
    {
        groupId: 'Basic info',
        children: [
            {
                groupId: 'Full name',
                children: [{ field: 'last_name' }, { field: 'first_name' }],
            },
            { field: 'age' },
        ],
    },
];

const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--10': {
        backgroundColor: getBackgroundColor(theme.palette.warning.main, theme.palette.mode),

        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--20': {
        backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),

        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--30': {
        backgroundColor: getBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
        ),
        color: "red",
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--40': {
        // backgroundColor: getBackgroundColor(
        //     theme.palette.warning.main,
        //     theme.palette.mode,
        // ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--50': {
        backgroundColor: getBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
        },
    },
}));

const ClientOrders = forwardRef((props, ref) => {
    const [searchText, setSearchText] = useState("");
    const [gridData, setGridData] = useState([]);
    const auth = useAuth();

    const navigate = useNavigate();

    const childRef = useRef();
    useImperativeHandle(ref, () => ({
        callReloadData(id) {
            reloadData(id);
        }
    }));

    const addId = (collection, idName) => {
        return collection.map(item => {
            // console.log('item:', {
            //     ...item,
            //     id: item[idName],
            // });
            return {
                ...item,
                id: item[idName],
            };
        });
    };

    // const processData = (data) => {
    //     return data.map(item => {
    //         let client = JSON.parse(item.data_json);
    //         return {
    //             ...item,
    //             id: item['order_id'],
    //             full_name: client['full_name'],
    //             nickname: client['nickname'],
    //             email: client['email'],

    //         };
    //     });
    // }

    const processData = async (data) => {
        return await data.map(item => {
            let client = JSON.parse(item.data_json);
            let state_str = "";

            switch (parseInt(item['state'] + "")) {
                case 10:
                    state_str = "New order";
                    break;
                case 20:
                    state_str = "Accepted";
                    break;
                case 30:
                    state_str = "Approved";
                    break;
                case 31:
                    state_str = "Request to cancel";
                    break;
                //----------------------------
                case 40:
                    state_str = "Processed";//"Put in PB";
                    break;
                case 60:
                    state_str = "Sent"; //"Put in SB";
                    break;
                case 90:
                    state_str = "Received";
                    break;
                default:
                    state_str = item['state']
            }

            let data = JSON.parse(item['data_json']);

            // let orderItems = [];
            // fetch("/api/orderitems/" + item['order_id'], {
            //     method: "GET",
            //     headers: getHeaders(auth),
            // })
            //     .then((response) => response.json())
            //     //.then((jsonData) => addId(jsonData, "client_id"))
            //     .then((rowData) => {
            //         if (rowData["result"]) { auth.logOut(); return;}

            //         orderItems = rowData;

            //     })
            //     .catch((error) => console.log(error));

            return {
                ...item,
                id: item['order_id'],
                full_name: client['full_name'] + "",
                nickname: client['nickname'] + "",
                email: client['email'] + "",
                ref_number: (client['ref_number'] ? client['ref_number'] : ""),
                state_str: state_str,
                data: data,
                orderItems: []
            };
        });
    }

    let client_id = props.clientData['client_id'];

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleRowClick = (params) => {
        console.log('Click Row: ', params.row);
        navigate("/orderdetail?id=" + params.row['order_id']);
        //  props.selectClient(params.row, false);
    };

    const handleSearchBtn = (event) => {
        if (searchText == null || searchText == undefined || searchText === "") {
            return;
        }

        fetch("/api/searchclients/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({ searchText: searchText }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                const rowData2 = addId(rowData, "client_id");
                setGridData(rowData2);
            })
            .catch((error) => console.log(error));
        //fetchData();

    }

    const reloadData = (id) => {
        if (props.clientData === null || props.clientData === undefined) {
            return;
        }

        client_id = id;

        if (client_id === null || client_id === undefined) {
            client_id = 0;
        }

        fetch("/api/clientorders/" + client_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                processData(rowData).then((rowData2) => {
                    //         console.log(rowData2);
                    setGridData(rowData2);
                });


            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    useEffect(() => {
        if (!auth.user) {
            auth.logOut();
            return;
        }

        reloadData(client_id);

    }, []);

    return (
        <div style={{ width: '100%' }}>

            <StyledDataGrid
                rows={gridData}
                columns={columns}
                checkboxSelection={false}
                disableMultipleRowSelection={true}
                columnGroupingModel={columnGroupingModel}
                density='compact'
                onRowClick={handleRowClick}
                //onRowSelectionModelChange={handleSelectionChange}

                pageSizeOptions={[5, 10, 25, 50, 100]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                    },
                }}
                getRowClassName={(params) => `super-app-theme--${params.row.state}`}
            />
            Total: {gridData.length} row(s)
        </div>
    );
});

export default ClientOrders;