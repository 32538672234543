import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Container from 'react-bootstrap/Container';
import ReactDOM from "react-dom";
import Popup from "reactjs-popup";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./userprofile.css";
import AvatarSelect from '../avatarSelect';
import { DoModal, ModalContent } from "../../utils/Modal";

import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';

export default function UserProfile() {

    const [state, setState] = useState({ open: false });
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [clientData, setClientData] = useState({});

    const [pw1, setPw1] = useState('');
    const [pw2, setPw2] = useState('');
    const [oldpw, setOldPw] = useState('');


    const openModal = () => {
        setState({ open: true });
    }
    const closeModal = () => {
        setState({ open: false });
    }

    const [isOpen, setIsopen] = useState(false);
    const showModal = () => setIsopen((prev) => !prev);

    const onSubmit = data => console.log(data);

    const auth = useAuth();
    if (auth.user === null || auth.user === undefined) {
        auth.logOut();
        // return;
    }



    const handlePw1 = (e) => {
        setPw1(e.target.value);
    }

    const handlePw2 = (e) => {
        setPw2(e.target.value);
    }

    const handleOldPw = (e) => {
        setOldPw(e.target.value);
    }

    const onChangePass = (e) => {

        e.preventDefault();

        if ((pw1 == null) || (pw1 == null)) {
            alert("Please enter new password");
            return;
        }
        if (pw1 != pw2) {
            alert("New password does not match");
            return;
        }

        // console.log(searchParams.get("m"));
        // console.log(searchParams.get("c"));
        // console.log(pw1);

        fetch("/api/resetcurrpwrd", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({
                usr_id: auth.user['usr_id'],
                oldpasswrd: oldpw,
                passwrd: pw1,
            }),
        })
            .then((response) => response.json())
            .then((rowData) => {
                //       console.log(rowData);
                if (rowData['result'] === "OK") {
                    alert("Reset password is successful");
                } else {
                    alert("Reset password is failed");
                }

            })
            .catch((error) => { console.log(error); alert("Error call API"); });

    }

    useEffect(() => {
        if (auth.user == null) {
            auth.logOut();
            // return;
        } else {
            setClientData(auth.user);
            setValue("usr_id", auth.user['usr_id']);
            setValue("usr_name", auth.user['usr_name']);
            setValue("first_name", auth.user['first_name']);
            setValue("last_name", auth.user['last_name']);
            setValue("full_name", auth.user['full_name']);
            setValue("email", auth.user['email']);
            setValue("telephone", auth.user['telephone']);
            setValue("gender", auth.user['gender']);
            setValue("country", auth.user['country']);
            setValue("created_date", auth.user['created_date']);
            setValue("updated_date", auth.user['updated_date']);
            setValue("notes", auth.user['notes']);
            setValue("state", auth.user['state']);

        }
    });

    const setAvatar = (avatar) => {

        console.log('avatar:', avatar['img']);
        if (avatar['img']) {
            fetch(avatar['img'])
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    return new Promise((res) => {
                        reader.onloadend = () => {
                            res(reader.result);
                        }
                    })
                }).then(data => {
                    console.log("IMG=", data);

                    clientData['avatar'] = data;
                    setClientData({ ...clientData });
                })
        }

    }

    return (

        <Container className="p-3 " fluid="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row style={{ width: "100%" }}>
                    <Col md="6">

                        <img className="mb-3 avatar" src={`${clientData['avatar']}`} alt="Avatar" onClick={showModal} />

                        {isOpen && (
                            <ModalContent onClose={() => setIsopen(false)} style={{ margin: "auto" }}>
                                <AvatarSelect closeModal={showModal} setAvatar={setAvatar} />
                            </ModalContent>
                        )}
                        <div className="mb-3">
                            <label>User ID:</label>&nbsp;<br />
                            <input type="text" placeholder="usr_id" {...register("usr_id", { required: true })} disabled={true} />
                        </div>

                        <div className="mb-3">
                            <label>Username:</label>&nbsp;<br />
                            <input type="text" placeholder="usr_name" {...register("usr_name", {})} disabled={true} />
                        </div>
                        <div className="mb-3">
                            <label>First Name:</label>&nbsp;<br />
                            <input type="text" placeholder="first_name" {...register("first_name", {})} />
                        </div>
                        <div className="mb-3">
                            <label>Last Name:</label>&nbsp;<br />
                            <input type="text" placeholder="last_name" {...register("last_name", {})} />
                        </div>
                        <div className="mb-3">
                            <label>Full Name:</label>&nbsp;<br />
                            <input type="text" placeholder="full_name" {...register("full_name", { required: true })} />
                        </div>
                        <div className="mb-3">
                            <label>Email:</label>&nbsp;<br />
                            <input type="email" placeholder="email" {...register("email", { required: true })} />
                        </div>
                        <div className="mb-3">
                            <button type="submit" className="btn btn-primary" style={{ width: "180px" }}>Update Profile</button>
                        </div>

                    </Col>
                    <Col md="6">
                        <div className="mb-3">
                            <label>Telephone:</label>&nbsp;<br />
                            <input type="text" placeholder="telephone" {...register("telephone", {})} />
                        </div>
                        <div className="mb-3">
                            <label>Gender:</label>&nbsp;<br />
                            <input type="text" placeholder="gender" {...register("gender", {})} />
                        </div>
                        <div className="mb-3">
                            <label>Country:</label>&nbsp;<br />
                            <input type="text" placeholder="country" {...register("country", {})} />
                        </div>
                        <div className="mb-3">
                            <label>Created Date:</label>&nbsp;<br />
                            <input type="text" placeholder="created_date" {...register("created_date", {})} />
                        </div>
                        <div className="mb-3">
                            <label>Updated Date:</label>&nbsp;<br />
                            <input type="text" placeholder="updated_date" {...register("updated_date", {})} />
                        </div>
                        <div className="mb-3">
                            <label>Notes:</label>&nbsp;<br />
                            <input type="text" placeholder="notes" {...register("notes", {})} />
                        </div>
                        <div className="mb-3">
                            <label>Status:</label>&nbsp;<br />
                            <select {...register("state")} disabled>
                                <option value="1">Active</option>
                                <option value="2">Lock</option>
                                {/* <option value="3">Wait approved</option> */}
                                <option value="9">Deleted</option>
                            </select>
                        </div>

                    </Col>
                </Row>
            </form>
            <Row>
                <hr />
                <Col md="6">
                    <div className="mb-3">
                        <label htmlFor="oldpw">Current password :</label><br />

                        <  input type="password" id="oldpw" name="oldpw"
                            title="Current Password"
                            value={oldpw} onChange={handleOldPw}
                            required />

                    </div>
                    <div className="mb-3">
                        <label htmlFor="psw">New password :</label><br />

                        <  input type="password" id="psw" name="psw" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                            title="Must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters"
                            value={pw1} onChange={handlePw1}
                            required />

                    </div>
                    <div className="mb-3">
                        <label htmlFor="psw2">Re-enter new password : </label><br />

                        <  input type="password" id="psw2" name="psw2" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                            title="Must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters"
                            value={pw2} onChange={handlePw2}
                            required />


                    </div>
                    <div className="mb-3">
                        <input type="button" value="Reset Password" className="btn btn-primary" style={{ width: "180px" }} onClick={onChangePass} />
                    </div>

                    <div id="message">
                        <h3>Password must contain the following:</h3>
                        <p id="letter" className="invalid">A <b>lowercase</b> letter</p>
                        <p id="capital" className="invalid">A <b>capital (uppercase)</b> letter</p>
                        <p id="number" className="invalid">A <b>number</b></p>
                        <p id="length" className="invalid">Minimum <b>6 characters</b></p>
                    </div>
                </Col>

                <Col md="6"></Col>

            </Row>

        </Container>


    );

}

