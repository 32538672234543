import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import SelectParts from '../shirtconfig/SelectParts';
import NewOrderStep1 from '../selectClient/NewOrderStep1';
import BodyMeasurement from '../measurements/BodyMeasurement';
import NewOrderReview from '../review/NewOrderReview';
import FabricSelect from '../fabricselect/FabricSelect';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import * as Icon from 'react-bootstrap-icons';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';

import ContactPageSharpIcon from '@mui/icons-material/ContactPageSharp';


import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import BallotIcon from '@mui/icons-material/Ballot';
import ShoppingCartCheckoutSharpIcon from '@mui/icons-material/ShoppingCartCheckoutSharp';
import OrderInfo from '../orderinfo/OrderInfo';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#3faf6c'
            // backgroundImage:
            //'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {

            backgroundColor: '#3faf6c',
            //     backgroundImage:
            //         'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 32,
    height: 32,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: '#3faf6c',
        // backgroundImage:
        //     'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        //  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: '#3faf6c',
        // backgroundImage:
        //     'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <GroupAddIcon fontSize="small" />,
        2: <ContactPageSharpIcon fontSize="small" />,
        3: <VideoLabelIcon fontSize="small" />,
        4: <BallotIcon fontSize="small" />,
        5: <ShoppingCartCheckoutSharpIcon fontSize="small" />,
        6: <DoneSharpIcon fontSize="small" />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};


const steps = ['Choose client', 'Create measurements', 'Choose fabric', 'Design shirt', 'Order and Shipping', 'Order Review'];

export default function OrderSteps() {
    const [orderData, setOrderData] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const [fabricsData, setFabricsData] = useState([]);
    // const fabricsData = []; // Update khong can render
    // const setFabricsData = (data) => {fabricsData=data }

    const bodyMeasurementRef = useRef(null);
    const orderInfoRef = useRef(null);

    const auth = useAuth();
    if (auth.user === null || auth.user === undefined) {
        auth.logOut();
        // return;


    }
    //console.debug(auth.user);

    const updateClientData = (data, nextStep) => {

        if (data === null || data === undefined) {
            return;
        }

        // console.log("Call orderDataUpdate");
        // console.log("Data=", data);

        var temp = orderData;
        for (var attrname in data) {
            temp[attrname] = data[attrname];
        }
        setOrderData(temp);
        console.debug(orderData);

        // Chuyen toi buoc tiep theo
        if (nextStep) {
            setActiveStep(1);
        }
    }

    const measurementUpdate = (data, nextStep) => {
        setOrderData(data);
        if (nextStep) {
            setActiveStep(2);
        }
    }

    const selectFabric = (data, nextStep) => {
        setOrderData(data);

        if (nextStep) {
            setActiveStep(3);
        }
    }

    const updateShirtConf = (data, nextStep) => {
        setOrderData(data);
        if (nextStep) {
            setActiveStep(4);
        }
    }


    const setOrderInfo = (data, nextStep) => {
        setOrderData(data);
        if (nextStep) {
            setActiveStep(5);
        }
    }

    const setOrderInfo4NewShirt = (data, nextStep) => {
        setOrderData(data);
        if (nextStep) {
            setActiveStep(3);
        }
    }


    const isStepOptional = (step) => {
        // if (activeStep === 3) {
        //     return (orderData['fabric_code'] === null || orderData['fabric_code'] === undefined)
        // }
        return false;//step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };


    const SendNewOrder = () => {
        let result = true;

        orderData['usr_id'] = auth.user.usr_id;

        fetch("/api/neworder", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({ orderData: orderData }),
        })
            .then((response) => response.json())
            .then((rowData) => {
                if (rowData["result"]) {
                    if (rowData["result"] === "timeout") {
                        auth.logOut();
                    } else if (rowData["result"] === "OK") {
                        //            console.log("Add new order return:", rowData[0]);
                        alert("Add new order successful");
                    } else {
                        alert("Error when query DB");
                    }
                } else {
                    //        console.log("Add new order return:", rowData[0]);
                    alert("Add new order successful");
                }
                // console.log(rowData);
            })
            .catch((error) => {
                console.log(error); alert("Error call API");
                result = false;
            });


        return result;
    }

    const handleNext = () => {

        if (activeStep === 0) {
            if (orderData['client_id'] == null) {
                alert("You have to select a client first");
                return;
            }
        }

        if (activeStep === 1) {
            console.debug('submitRef=', bodyMeasurementRef);
            if (bodyMeasurementRef.current) {
                if (!bodyMeasurementRef.current.doSubmit()) return;

                //bodyMeasurementRef.current.requestSubmit();
            }
            if (orderData['measurements'] == null) {
                alert("You have to make some measurements first");

                return;
            }

            // return;
        }

        if (activeStep === 2) {
            if (orderData.order_infos == null ||
                orderData.order_infos[orderData.cur_order]['fabric_code'] == null) {
                alert("You have to select a fabric first");
                return;
            }
        }

        if (activeStep === 3) {
            if (orderData.order_infos[orderData.cur_order]['shirtConfig'] == null) {
                alert("You have to select a shirtConfig first");
                return;
            }
        }

        if (activeStep === 4) {

            orderInfoRef.current.doSubmit();
            // if (orderData['order_info'] == null) {
            //     alert("You have to enter and confirm your order first");
            //     return;
            // }
        }

        // Xu ly finished
        if (activeStep === steps.length - 1) {
            if (!SendNewOrder()) {
                return;
            }
        }

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleResetAll = () => {
        setOrderData({});
        setActiveStep(0);
    };

    return (
        <>

            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} sx={{ width: '80%', margin: 'auto' }}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                {/* <StepLabel {...labelProps}>{label}</StepLabel> */}
                                <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <br />

                {activeStep === steps.length ? (
                    <React.Fragment >
                        <Container className="p-3 " style={{ width: "600px" }}>
                            <Row style={{ width: "100%", alignItems: "center" }}>

                                <h1>Thank you.</h1>
                                <strong> Your order {orderData.order_id} was successfully created.</strong>
                                <br></br>
                            </Row>
                            <Row><br />
                                <Button onClick={handleReset}>Order another shirt</Button>
                                <br />
                                <Button onClick={handleResetAll}>Order for other client</Button>
                            </Row>
                        </Container>


                    </React.Fragment>
                ) : (
                    <React.Fragment >

                        {activeStep === 0 && <NewOrderStep1 updateClientData={updateClientData} />}
                        {activeStep === 1 && <BodyMeasurement orderData={orderData} measurementUpdate={measurementUpdate} ref={bodyMeasurementRef} />}
                        {activeStep === 2 && <FabricSelect orderData={orderData} selectFabric={selectFabric}
                            fabricsData={fabricsData} setFabricsData={setFabricsData} />}
                        {activeStep === 3 && <SelectParts orderData={orderData} updateShirtConf={updateShirtConf}
                            fabricsData={fabricsData} setFabricsData={setFabricsData} />}
                        {activeStep === 4 && <OrderInfo orderData={orderData}
                            setOrderInfo={setOrderInfo}
                            setOrderInfo4NewShirt={setOrderInfo4NewShirt}
                            fabricsData={fabricsData} setFabricsData={setFabricsData}
                            ref={orderInfoRef} />}
                        {activeStep === 5 && <NewOrderReview orderData={orderData} />}


                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, magin: "auto", paddingLeft: "200px", paddingRight: "200px" }}>
                            {activeStep > 0 && <Fab color="success" variant="extended" onClick={handleBack}>
                                <Icon.ArrowLeft />
                                Back
                            </Fab>
                            }

                            <Box sx={{ flex: '1 1 auto' }} />
                            {isStepOptional(activeStep) && (
                                <Fab color="primary" variant="extended" onClick={handleSkip}>
                                    <Icon.SkipEnd />
                                    Skip
                                </Fab>
                            )}
                            <Fab color="success" variant="extended" onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                <Icon.ArrowRight />

                            </Fab>
                        </Box>
                    </React.Fragment>
                )}


            </Box >


        </>
    );
}