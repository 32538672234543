import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from "../../../../hooks/AuthProvider";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DoModal, ModalContent } from "../../../../utils/Modal";

import { getHeaders } from '../../../../utils/apis';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AlarmIcon from '@mui/icons-material/Alarm';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FabricSelect from '../../fabricselect/FabricSelect';
import 'reactjs-popup/dist/index.css';

import OrderStepBar from '../OrderStepBar';

import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import * as Icon from 'react-bootstrap-icons';

import './OrderInfo.css';

function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}


//export default function OrderInfo(props) {
const OrderInfo2 = forwardRef(function OrderInfo(props, ref) {
    const [orderData, setOrderData] = useState(JSON.parse(localStorage.getItem('orderData')));

    const { register, control, handleSubmit, reset, trigger, setError, formState: { errors }, getValues }
        = useForm({ defaultValues: { orderInfoData: orderData['order_infos'], address1: orderData['address1'] } });

    const { fields, append, prepend, remove, swap, move, insert, update } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormProvider)
        name: "orderInfoData", // unique name for your Field Array
    });

    const noneFabric = { fabric_code: "P1", large_img: null, small_img: "./images/fabrics/P1.jpg", full_name: "P1", state: "1" };

    const [isOpen, setIsopen] = useState(false);
    const [fabricsData, setFabricsData] = useState([]);
    const [curFabric, setCurFabric] = useState({ small_img: "" });

    const navigate = useNavigate();


    //const [curData, setCurOrderInfo] = useState(props.orderData);

    const [showFabricSelector, setShowSelector] = useState(false);
    const [sumOfShirt, setSumOfShirt] = useState(1);
    const fabricTempData = {};

    const showModal = () => setIsopen((prev) => !prev);

    const auth = useAuth();
    if (!auth.user) {
        auth.logOut();
        // return;
    }



    //const orderData = props.orderData;



    const addMoreShirt = () => {

        setShowSelector(true);
        let temp = { ...orderData };
        temp.order_infos.push({ ...orderData.order_infos[orderData.order_infos.length - 1] });
        temp.cur_order = temp.order_infos.length - 1;
        //   console.log("addMoreShirt", temp);
        setOrderData(temp);
        append({ num_of_shirt: 1, notes: "" })

    }
    const handleSelectorClose = () => {
        setShowSelector(false);
        localStorage.setItem('orderData', JSON.stringify(orderData));
        navigate('/designshirt');
        // props.setOrderInfo4NewShirt(curData, true);
    }

    const updateSumOfShirt = () => {
        let sum = 0;
        orderData.order_infos.forEach((e) => {
            sum += parseInt(e.num_of_shirt, 10);
        });
        setSumOfShirt(sum);
    }

    const updateFormData = () => {

        let data = getValues('orderInfoData');
        console.debug("Data=", data);
        setTimeout(() => {
            let sum = 0;
            data.forEach((e) => {
                if (parseInt(e.num_of_shirt, 10) < 1) {
                    alert("Invalid number of shirts");
                } else {
                    sum += parseInt(e.num_of_shirt, 10);
                }
            });
            setSumOfShirt(sum);
        }, 500)


    }

    const selectFabric = (data, next) => {
        //  alert(JSON.stringify(data));
        let lastIdx = orderData.order_infos.length - 1;
        let temp = { ...orderData };
        temp.order_infos[lastIdx].fabric_code = data.order_infos[0].fabric_code;
        temp.order_infos[lastIdx].small_img = data.order_infos[0].small_img;

        let shirtConfig = temp.order_infos[lastIdx]['shirtConfig'];
        if (shirtConfig) {
            let shirtFabric = fabricsData.find(obj => obj['fabric_code'] === data.order_infos[0].fabric_code);

            if (shirtConfig['insCollarFabric']) {
                if (shirtConfig.insCollarFabric['fabric_code'] !== "None") {
                    shirtConfig.insCollarFabric = shirtFabric;
                }
            }
            if (shirtConfig['insCuffFabric']) {
                if (shirtConfig.insCuffFabric['fabric_code'] !== "None") {
                    shirtConfig.insCuffFabric = shirtFabric;

                }
            }
        }
        setOrderData(temp);
        updateSumOfShirt();
        // if (next) {
        handleSelectorClose();
        //  }
    }

    const removeShirt = (index) => {
        let temp = { ...orderData };
        console.log("removeShirt ", index);
        if (index == null || index === undefined) {
            return;
        }
        temp.order_infos.splice(index, 1);
        temp.cur_order = 0;
        setOrderData(temp);

        updateSumOfShirt();
        remove(index);
    }

    const moveToShirtCfg = (index) => {

        if (index > orderData.order_infos.length - 1) {
            return 0;
        }

        let data = getValues();
        orderData.order_infos.forEach((e, idx, arr) => {
            arr[idx]['num_of_shirt'] = data.orderInfoData[idx]['num_of_shirt'];
            arr[idx]['notes'] = data.orderInfoData[idx]['notes'];
        });

        let temp = { ...orderData };
        temp.cur_order = index;
        console.debug("Move to shirt: ", index);

        setOrderData(temp);
        // console.debug("temp: ", temp);
        // console.debug("orderData: ", orderData);


        localStorage.setItem('orderData', JSON.stringify(temp));
        navigate('/designshirt');


    }


    useImperativeHandle(ref, () => {

        return {
            getAlert() {
                alert("getAlert from Child");
            },
            doSubmit() {
                onSubmit(getValues());
            }
        };
    }, []);

    const onSubmit = (data) => {

        if (parseInt(data.num_of_shirt, 10) <= 0) {
            alert("Please enter valid number of shirt you want to order");
            return
        }
        // Update orderData
        let invalid_data = false;
        orderData.order_infos.forEach((e, idx, arr) => {
            arr[idx]['num_of_shirt'] = data.orderInfoData[idx]['num_of_shirt'];
            console.debug(data.orderInfoData[idx]['num_of_shirt']);
            if (isNaN(data.orderInfoData[idx]['num_of_shirt']) || data.orderInfoData[idx]['num_of_shirt'] === 0) {
                alert("The quantity not valid");
                invalid_data = true;
                arr[idx]['num_of_shirt'] = 1;
            }
            // if (!isNumeric(data.orderInfoData[idx]['num_of_shirt']) || parseInt(data.orderInfoData[idx]['num_of_shirt']) < 1) {

            //     alert("The quantity not valid");
            //     invalid_data = true;
            //     return;
            // }
            arr[idx]['notes'] = data.orderInfoData[idx]['notes'];
        });

        orderData['address1'] = data.address1;


        if (!invalid_data) {
            // props.setOrderInfo(orderData, false);
            localStorage.setItem('orderData', JSON.stringify(orderData));
            navigate('/ordersubmit');
        }
        // console.debug("Data out:", orderData);
    }


    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }

        //update(0, { num_of_shirt: 1, notes: "" });
        //     //setCurFabric(fabricsData.find(obj => obj['fabric_code'] === props.orderData.order_infos[0]['fabric_code']));
        updateFormData();

        fetch("/api/fabrics/" + auth.user['tailorshop_id'], {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                // const rowData2 = addId(rowData, "client_id");
                setFabricsData(rowData);

            })
            .catch((error) => console.log(error));

    }, []);

    useEffect(() => {
        localStorage.setItem('orderData', JSON.stringify(orderData));
    }, [orderData]);

    const handleBack = () => {
        navigate('/designshirt')
    }

    const handleNext = () => {

        navigate('/ordersubmit')
    }

    return (
        <>
            <OrderStepBar activeStep="4" />
            <Container className="p-3 " style={{ width: "800px" }}>
                <Row style={{ width: "100%" }}>
                    <form onSubmit={handleSubmit(onSubmit)}>


                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <h3>Order Info</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="mb-3">
                                    <table>

                                        {orderData.order_infos.map((item, index) => (
                                            <tbody key={index}>
                                                <tr ><td colSpan={3} ><p>{index + 1}. Shirt</p></td></tr>
                                                <tr style={{ padding: "0px", border: "1px solid" }}>
                                                    <td className="shirt-info">
                                                        <label>{item.fabric_code}</label>&nbsp;<br />
                                                        <DoModal onOpen={showModal}>
                                                            <div className="holder img-fluid">
                                                                <img src={item.small_img} alt="" style={{
                                                                    width: "60px", height: "60px",
                                                                    display: "blocks", borderRadius: "10%", boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)"
                                                                }} />
                                                            </div>
                                                        </DoModal>
                                                        {isOpen && (
                                                            <ModalContent onClose={() => setIsopen(false)} style={{ margin: "auto", width: "800px", maxWidth: "800px" }}>
                                                                <img src={item.small_img} alt="" style={{ width: "320px", height: "320px", margin: "auto" }} />
                                                            </ModalContent>
                                                        )}
                                                    </td>
                                                    <td className="shirt-info">
                                                        <label>Quantity :</label>&nbsp;<br />
                                                        <input type="number" placeholder="num_of_shirt" {...register(`orderInfoData.${index}.num_of_shirt`,
                                                            {
                                                                required: true,
                                                                valueAsNumber: true,
                                                                min: 1,
                                                                onChange: (e) => updateFormData()
                                                            })} style={{ width: "100px" }} />&nbsp;

                                                    </td>

                                                    <td className="shirt-info">
                                                        <div className="mb-3">
                                                            <label>Notes for this shirt:</label>&nbsp;<br />
                                                            <textarea id="notes" name="notes" rows="2" cols="35" {...register(`orderInfoData.${index}.notes`, {})}>
                                                            </textarea>
                                                        </div>
                                                    </td>
                                                    <td className="shirt-info">
                                                        <IconButton id={index} aria-label="showconf" color="red" onClick={() => { moveToShirtCfg(index) }} >
                                                            <AssignmentIcon color="success" />
                                                        </IconButton>
                                                        {orderData.order_infos.length > 1 &&

                                                            <IconButton id={index} aria-label="delete" color="red" onClick={() => { removeShirt(index) }} >
                                                                <DeleteIcon color="danger" />
                                                            </IconButton>

                                                        }
                                                        &nbsp;
                                                    </td>
                                                </tr>

                                            </tbody>))}
                                        <tbody>
                                            <tr>
                                                <td className="shirt-info" colSpan={3}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <IconButton color="primary" aria-label="add to shopping cart" style={{ width: "64px" }} onClick={addMoreShirt}>
                                                            <AddShoppingCartIcon />
                                                        </IconButton>

                                                        Add more shirt with the same configuration
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <strong>TOTAL: {sumOfShirt}</strong>
                                                    </div>
                                                    <Modal show={showFabricSelector} onHide={handleSelectorClose} dialogClassName="my-modal">
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Fabric Selector</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <FabricSelect orderData={fabricTempData} selectFabric={selectFabric}
                                                                fabricsData={fabricsData} setFabricsData={setFabricsData} />
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={handleSelectorClose}>
                                                                Close
                                                            </Button>

                                                        </Modal.Footer>
                                                    </Modal>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                            </AccordionDetails>
                        </Accordion>




                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >

                                <h3>Shipping info</h3>
                            </AccordionSummary>
                            <AccordionDetails>

                                Client ID: <strong> {orderData.client_id} </strong><br />
                                Name: <strong> {orderData.first_name} {orderData.last_name}</strong> <br />
                                Nickname: <strong> {orderData.nickname}</strong> <br />
                                Email: <strong> {orderData.email}</strong> <br />
                                Telephone: <strong> {orderData.telephone}</strong> <br />
                                {/* PO Code: <strong> {orderData.po_code}</strong> <br /> */}
                                <label>Address:</label>&nbsp;<br />

                                <textarea id="notes" name="notes" rows="4" cols="45" {...register("address1", {})} />
                            </AccordionDetails>
                        </Accordion>


                        {/* <button type="submit" className="btn btn-primary" style={{ width: "20vw" }}>Confirm shipping address</button> */}
                    </form>


                </Row >
            </Container >
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, magin: "auto", paddingLeft: "300px", paddingRight: "300px" }}>
                <Fab color="success" variant="extended" onClick={handleBack}>
                    <Icon.ArrowLeft />
                    Back
                </Fab>


                <Box sx={{ flex: '1 1 auto' }} />
                {/* {isStepOptional(activeStep) && (
                            <Fab color="primary" variant="extended" onClick={handleSkip}>
                                <Icon.SkipEnd />
                                Skip
                            </Fab>
                        )} */}
                <Fab color="success" variant="extended" onClick={handleSubmit(onSubmit)}>
                    Next
                    <Icon.ArrowRight />

                </Fab>
            </Box>
            <br />
            {/* DEBUG : {JSON.stringify(orderData)} */}
        </>
    );
});

export default OrderInfo2;