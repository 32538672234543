import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import './BodyMeasurement.css';
import OldShirtMeasurement from './OldShirtMeasurement';

const sleep = ms => new Promise(res => setTimeout(res, ms));

const BodyMeasurement = forwardRef((props, ref) => {

    const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm();
    const [measureType, setMeasureType] = useState("1");
    const [typeIdx, setTypeIdx] = useState(0);
    const [lastorder, setLastOrder] = useState([]);
    const [showLastOrder, setShowLastOrder] = useState(false);
    const [lastMeasures, setLastMeasures] = useState([]);


    const guides = [
        { id: "neck", img_src: "measure-collar.png", note: "From button to center of button hole" },
        { id: "chest", img_src: "measure-chest.png", note: "Chest circumference at base of arm hole" },
        { id: "waist", img_src: "measure-waist.png", note: "Waist circumference" },
        { id: "hip", img_src: "measure-seat.png", note: "Hip circumference" },
        { id: "shoulders", img_src: "measure-shoulder.png", note: null },
        { id: "arm_length", img_src: "measure-sleeve.png", note: null },
        { id: "upper_arm", img_src: "measure-upper_arm.png", note: "Upper Arm circumference 10cm bellow arm hole" },
        { id: "wrist", img_src: "measure-wrist.png", note: "End to end of cuff" },
        { id: "back_length", img_src: "measure-back.png", note: "At center" },
    ]

    const auth = useAuth();
    if (auth.user === null || auth.user === undefined) {
        auth.logOut();
        // return;
    }



    const curData = props.orderData;


    const onTypeSelect = (event) => {
        //event.preventDefault();
        // event.persist();
        // event.stopPropagation();
        setMeasureType(event.target.value);
        //console.debug('select : ', event.target.value) // selected event will trigger

        if (event.target.value === "1") {
            curData['measurements'] = lastMeasures[0];
            reset(lastMeasures[0]);
        } else {
            if (lastMeasures[1]) {
                curData['measurements'] = lastMeasures[1];
                reset(lastMeasures[1]);
            }
        }
    }

    const onLastOrderChange = (event) => {
        event.preventDefault();

        let lo = lastorder[event.target.value];
        let data = JSON.parse(lo.data_json);
        let lastMeasure = data.measurements;
        // alert(JSON.stringify(lastMeasure));
        reset(lastMeasure);
    }


    const handleLoadLastOrder = (event) => {

        if (measureType === "1") {
            let result = lastMeasures.find((e) => e.measure_type === 1);

            if (result) {
                curData['measurements'] = result;
                reset(result);
            } else {
                alert("Client does not have previous body measurements");
            }
        } else {
            let result = lastMeasures.find((e) => e.measure_type === 2);

            if (result) {
                curData['measurements'] = result;
                reset(result);
            } else {
                alert("Client does not have previous shirt measurements");
            }
        }
    }

    useEffect(() => {
        if (curData?.client_id === null || curData?.client_id === undefined) {
            return
        }

        if (auth.user['tailorshop_id'] === '2') {
            setMeasureType('2');
        }

        fetch("/api/measurements/" + curData.client_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                console.debug("curData.client_id=", curData.client_id);
                console.debug("DEBUG measurements: fetch =", rowData);
                if (rowData["result"]) { auth.logOut(); return }

                curData['measurements'] = rowData[0];
                props.measurementUpdate(curData, false);

                setLastMeasures(rowData);

                reset(rowData[0]);
            })
            .catch((error) => console.log(error));
        //fetchData();


        fetch("/api/lastorder/" + curData.client_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                console.debug("curData.client_id=", curData.client_id);
                console.debug("DEBUG: fetch =", rowData);
                if (rowData["result"]) { auth.logOut(); return }

                setLastOrder(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();


    }, []);

    const measureInputFocus = (event) => {

        const index = guides.findIndex(item => item.id === event.target.id);
        //   alert(index);
        if (index === -1) {
            index = 0;
        }
        setTypeIdx(index);
    }

    useImperativeHandle(ref, () => {

        return {
            getAlert() {
                alert("getAlert from Child");
            },
            doSubmit() {
                return onSubmit(getValues());
            }
        };
    }, []);

    const onSubmit = (data) => {

        if (auth.user === null || auth.user === undefined) {
            auth.logOut();
            return false;
        }

        if (measureType === "0") {
            alert("Please select a measure type first");
            return false;
        }

        console.debug("DEBUG data:", data);
        console.debug("DEBUG getValues():", getValues());

        data['client_id'] = curData.client_id;
        delete data['measure_id'];

        if (!data['remark']) delete data['remark'];
        if (!data['body_height'] || data['body_height'] == "") delete data['body_height'];
        if (!data['body_weight'] || data['body_weight'] == "") delete data['body_weight'];

        console.debug('measureType=', measureType)
        data['measure_type'] = measureType;

        fetch("/api/entity/measurements", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((rowData) => {
                if (rowData["result"]) {
                    if (rowData["result"] === "timeout") {
                        auth.logOut();
                    } else {
                        alert("Error when query DB");
                    }
                } else {
                    //          console.log("Add new return:", rowData[0]);
                    data['measurement_id'] = rowData[0]['measurement_id'];
                    curData['measurements'] = data;
                    // alert("Add measurement successful");
                    props.measurementUpdate(curData, true);
                    reset();
                }
                // console.log(rowData);
            })
            .catch((error) => { console.log(error); alert("Error call API"); });

        console.debug('>>', data);
        return true;
    }

    // console.log(errors);
    return (
        <>
            <div className='bodyMeasure'>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <Container className="p-3 ">
                        <Row style={{ width: "100%" }}>
                            <div className='mb-1'>
                                <label>Client ID : {curData.client_id}</label>
                            </div>
                            <div className='mb-1'>
                                <label> Full name : {curData.full_name}</label>
                            </div>
                            <div className='mb-1'>
                                <label> Email : {curData.email}</label>
                            </div>
                            <br /><br />
                            <label>Measurement type:</label>
                        </Row>
                        <Row>
                            <Col md='4' >

                                <select className="form-select" aria-label="Select measurement type" style={{ width: "230px" }} onChange={onTypeSelect} value={measureType}>

                                    <option value="1">Body Measurement</option>
                                    <option value="2" >Shirt Measurement</option>
                                    {/* <option value="3">Measurement from the last order</option> */}
                                </select>

                                {/* {measureType} */}
                            </Col>
                            <Col md='8'>
                                <button type="button" className="btn btn-success" style={{ with: "250px" }} onClick={handleLoadLastOrder}>From last order</button>
                            </Col>

                        </Row>

                        {/* {showLastOrder &&
                            <Row style={{ width: "100%" }}>
                                <label>Previous orders:</label><br />
                                <select className="form-select" aria-label="Select order" style={{ width: "450px" }} onChange={onLastOrderChange}>

                                    {lastorder && lastorder.map((lo, idx) => (
                                        <option value={idx} key={idx}> {lo.body_txt} (Order ID: {lo.order_id} on {lo.order_time.substring(0, 10)})</option>
                                    ))}

                                </select>
                            </Row>
                        } */}

                        <hr />
                        {(measureType === "1" || measureType === "3") && <>

                            <Row style={{ width: "100%", marginTop: "20px" }}>
                                <Col md="6">
                                    {/* <div className="mb-3">
                                    <label>Measure ID:</label><br />
                                    <input id=""  type="text" disabled={true} />
                                </div> */}

                                    <div className="mb-3">
                                        <label htmlFor="neck">Neck (cm):</label><br />
                                        <input id="neck" type="number"  {...register("neck", {
                                            required: { value: true, message: "This data is required" },
                                            min: { value: 30, message: "Invalid value" },
                                            max: { value: 60, message: "Invalid value" }
                                        })} />
                                        <div style={{ color: "red" }}>
                                            {errors['neck'] && errors['neck'].message}
                                        </div>
                                    </div>


                                    <div className="mb-3">
                                        <label htmlFor="chest">Chest (cm):</label><br />
                                        <input id="chest" type="number"  {...register("chest", {
                                            required: { value: true, message: "This data is required" },
                                            min: { value: 80, message: "Invalid value" },
                                            max: { value: 190, message: "Invalid value" }
                                        })} />
                                        <div style={{ color: "red" }}>
                                            {errors['chest'] && errors['chest'].message}
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="waist">Waist (cm):</label><br />
                                        <input id="waist" type="number"  {...register("waist", {
                                            required: true, min: 70, max: 190,
                                            required: { value: true, message: "This data is required" },
                                            min: { value: 70, message: "Invalid value" },
                                            max: { value: 190, message: "Invalid value" }
                                        })} />
                                        <div style={{ color: "red" }}>
                                            {errors['waist'] && errors['waist'].message}
                                        </div></div>
                                    <div className="mb-3">
                                        <label htmlFor="hip">Hips (cm):</label><br />
                                        <input id="hip" type="number" {...register("hip", {
                                            required: { value: true, message: "This data is required" },
                                            min: { value: 70, message: "Invalid value" },
                                            max: { value: 190, message: "Invalid value" }
                                        })} />
                                        <div style={{ color: "red" }}>
                                            {errors['hip'] && errors['hip'].message}
                                        </div></div>
                                    <div className="mb-3">
                                        <label htmlFor="shoulders">Shoulders (cm):</label><br />
                                        <input id="shoulders" type="number"  {...register("shoulders", {
                                            required: { value: true, message: "This data is required" },
                                            min: { value: 30, message: "Invalid value" },
                                            max: { value: 65, message: "Invalid value" }
                                        })} />
                                        <div style={{ color: "red" }}>
                                            {errors['shoulders'] && errors['shoulders'].message}
                                        </div></div>

                                    <div className="mb-3">
                                        <label htmlFor="arm_length">Arm Length (cm):</label><br />
                                        <input id="arm_length" type="number"  {...register("arm_length", {
                                            required: { value: true, message: "This data is required" },
                                            min: { value: 40, message: "Invalid value" },
                                            max: { value: 90, message: "Invalid value" }
                                        })} />
                                        <div style={{ color: "red" }}>
                                            {errors['arm_length'] && errors['arm_length'].message}
                                        </div></div>
                                    <div className="mb-3">
                                        <label htmlFor="upper_arm">Upper Arm (cm):</label><br />
                                        <input id="upper_arm" type="number"  {...register("upper_arm", {
                                            required: { value: true, message: "This data is required" },
                                            min: { value: 20, message: "Invalid value" },
                                            max: { value: 50, message: "Invalid value" }
                                        })} />
                                        <div style={{ color: "red" }}>
                                            {errors['upper_arm'] && errors['upper_arm'].message}
                                        </div></div>
                                    <div className="mb-3">
                                        <label htmlFor="wrist">Wrist (cm):</label><br />
                                        <input id="wrist" type="number"  {...register("wrist", {
                                            required: { value: true, message: "This data is required" },
                                            min: { value: 12, message: "Invalid value" },
                                            max: { value: 35, message: "Invalid value" }
                                        })} />
                                        <div style={{ color: "red" }}>
                                            {errors['wrist'] && errors['wrist'].message}
                                        </div></div>
                                    <div className="mb-3">
                                        <label htmlFor="back_length">Back Length (cm):</label><br />
                                        <input id="back_length" type="number"  {...register("back_length", {
                                            required: { value: true, message: "This data is required" },
                                            min: { value: 50, message: "Invalid value" },
                                            max: { value: 100, message: "Invalid value" }
                                        })} />
                                        <div style={{ color: "red" }}>
                                            {errors['back_length'] && errors['back_length'].message}
                                        </div></div>
                                    {/* <div className="mb-3">
                                    <label>More Notes:</label><br />
                                    <input id=""  type="text" placeholder="Notes" {...register("notes", { required: false })} /></div> */}


                                </Col>
                                <Col md="6">
                                    Image
                                </Col>
                            </Row>
                            <Row>
                                <Col><br />Optional Information:</Col>
                            </Row>
                            <Row>


                                {/* <div className="mb-3">
                                    <label>Remark for measurement:</label><br />
                                    <input id=""  type="text" placeholder="remark" {...register("remark", { required: true })} /></div> */}
                                <Col>
                                    <label htmlFor="body_height">Body Height (cm):</label><br />
                                    <input id="body_height" type="number"  {...register("body_height", {
                                        required: false,
                                        min: { value: 120, message: "Invalid value" },
                                        max: { value: 250, message: "Invalid value" }
                                    })} />
                                    <div style={{ color: "red" }}>
                                        {errors['body_height'] && errors['body_height'].message}
                                    </div></Col>
                                <Col>
                                    <label htmlFor="body_weight">Body Weight (kg):</label><br />
                                    <input id="body_weight" type="number" {...register("body_weight", {
                                        required: false,
                                        min: { value: 40, message: "Invalid value" },
                                        max: { value: 250, message: "Invalid value" }
                                    })} />
                                    <div style={{ color: "red" }}>
                                        {errors['body_weight'] && errors['body_weight'].message}
                                    </div></Col>
                                <Col>
                                    <label htmlFor="shoe_size">Shoe size:</label><br />
                                    <input id="shoe_size" type="number"  {...register("shoe_size", {
                                        required: false,
                                        min: { value: 32, message: "Invalid value" },
                                        max: { value: 46, message: "Invalid value" }
                                    })} />
                                    <div style={{ color: "red" }}>
                                        {errors['shoe_size'] && errors['shoe_size'].message}
                                    </div></Col>

                            </Row>
                        </>
                        }

                        {measureType === "2" && <>
                            <Row style={{ width: "100%" }}>
                                <Col>
                                    <div className="mb-2">
                                        <label htmlFor="neck">Neck (cm):</label><br />
                                        <input id="neck" type="number"  {...register("neck", {
                                            required: { value: true, message: "This data is required" },
                                            min: { value: 30, message: "Invalid value" },
                                            max: { value: 60, message: "Invalid value" }
                                        })} onFocus={measureInputFocus} />
                                        <div style={{ color: "red" }}>
                                            {errors['neck'] && errors['neck'].message}
                                        </div></div>

                                    <div className="mb-2">
                                        <label htmlFor="chest">Chest (cm):</label><br />
                                        <input id="chest" type="number"  {...register("chest",
                                            {
                                                required: { value: true, message: "This data is required" },
                                                min: { value: 80, message: "Invalid value" },
                                                max: { value: 190, message: "Invalid value" }
                                            }
                                        )} onFocus={measureInputFocus} /><div style={{ color: "red" }}>
                                            {errors['chest'] && errors['chest'].message}
                                        </div></div>


                                    <div className="mb-2">
                                        <label htmlFor="waist">Waist (cm):</label><br />
                                        <input id="waist" type="number"  {...register("waist",
                                            {
                                                required: { value: true, message: "This data is required" },
                                                min: { value: 70, message: "Invalid value" },
                                                max: { value: 190, message: "Invalid value" }
                                            }
                                        )} onFocus={measureInputFocus} /><div style={{ color: "red" }}>
                                            {errors['waist'] && errors['waist'].message}
                                        </div></div>

                                    <div className="mb-2">
                                        <label htmlFor="hip">Hips (cm):</label><br />
                                        <input id="hip" type="number" {...register("hip",
                                            {
                                                required: { value: true, message: "This data is required" },
                                                min: { value: 70, message: "Invalid value" },
                                                max: { value: 190, message: "Invalid value" }
                                            }
                                        )} onFocus={measureInputFocus} /><div style={{ color: "red" }}>
                                            {errors['hip'] && errors['hip'].message}
                                        </div></div>
                                    <div className="mb-2">
                                        <label htmlFor="shoulders">Shoulders (cm):</label><br />
                                        <input id="shoulders" type="number"  {...register("shoulders",
                                            {
                                                required: { value: true, message: "This data is required" },
                                                min: { value: 30, message: "Invalid value" },
                                                max: { value: 65, message: "Invalid value" }
                                            }
                                        )} onFocus={measureInputFocus} /><div style={{ color: "red" }}>
                                            {errors['shoulders'] && errors['shoulders'].message}
                                        </div></div>
                                    <div className="mb-2">
                                        <label htmlFor="arm_length">Arm Length (cm):</label><br />
                                        <input id="arm_length" type="number" {...register("arm_length",
                                            {
                                                required: { value: true, message: "This data is required" },
                                                min: { value: 40, message: "Invalid value" },
                                                max: { value: 90, message: "Invalid value" }
                                            }
                                        )} onFocus={measureInputFocus} /><div style={{ color: "red" }}>
                                            {errors['arm_length'] && errors['arm_length'].message}
                                        </div></div>

                                    <div className="mb-2">
                                        <label htmlFor="upper_arm">Upper Arm (cm):</label><br />
                                        <input id="upper_arm" type="number"  {...register("upper_arm",
                                            {
                                                required: { value: true, message: "This data is required" },
                                                min: { value: 20, message: "Invalid value" },
                                                max: { value: 50, message: "Invalid value" }
                                            }
                                        )} onFocus={measureInputFocus} /><div style={{ color: "red" }}>
                                            {errors['upper_arm'] && errors['upper_arm'].message}
                                        </div></div>
                                    <div className="mb-2">
                                        <label htmlFor="wrist">Wrist (cm):</label><br />
                                        <input id="wrist" type="number"  {...register("wrist",
                                            {
                                                required: { value: true, message: "This data is required" },
                                                min: { value: 12, message: "Invalid value" },
                                                max: { value: 35, message: "Invalid value" }
                                            }
                                        )} onFocus={measureInputFocus} /><div style={{ color: "red" }}>
                                            {errors['wrist'] && errors['wrist'].message}
                                        </div></div>
                                    <div className="mb-2">
                                        <label htmlFor="back_length">Back Length (cm):</label><br />
                                        <input id="back_length" type="number"  {...register("back_length",
                                            {
                                                required: { value: true, message: "This data is required" },
                                                min: { value: 50, message: "Invalid value" },
                                                max: { value: 100, message: "Invalid value" }
                                            }
                                        )} onFocus={measureInputFocus} /><div style={{ color: "red" }}>
                                            {errors['back_length'] && errors['back_length'].message}
                                        </div></div>
                                </Col>
                                <Col >
                                    <img src={"./images/shirt_measure/" + guides[typeIdx].img_src} className="img-fluid" alt="Fabric" style={{ height: "300px", display: "block", margin: "auto", span: "20px" }} />
                                    <br />
                                    {guides[typeIdx].note && <> <i>{guides[typeIdx].note}</i></>}


                                </Col>


                            </Row>

                            <Row>
                                <Row>
                                    <Col><br />Optional Information:</Col>
                                </Row>
                            </Row><Row>
                                {/* <div className="mb-3">
                                    <label>Remark for measurement:</label><br />
                                    <input id=""  type="text" placeholder="remark" {...register("remark")} /></div> */}
                                <Col>
                                    <label htmlFor="body_height">Body Height (cm):</label><br />
                                    <input id="body_height" type="number"  {...register("body_height", {
                                        required: false,
                                        min: { value: 120, message: "Invalid value" },
                                        max: { value: 250, message: "Invalid value" }
                                    })} /><div style={{ color: "red" }}>
                                        {errors['body_height'] && errors['body_height'].message}
                                    </div></Col>
                                <Col>
                                    <label htmlFor="body_weight">Body Weight (kg):</label><br />
                                    <input id="body_weight" type="number"  {...register("body_weight", {
                                        required: false,
                                        min: { value: 40, message: "Invalid value" },
                                        max: { value: 250, message: "Invalid value" }
                                    })} /><div style={{ color: "red" }}>
                                        {errors['body_weight'] && errors['body_weight'].message}
                                    </div></Col>
                                <Col>
                                    <label htmlFor="shoe_size">Shoe size:</label><br />
                                    <input id="shoe_size" type="number"  {...register("shoe_size", {
                                        required: false,
                                        min: { value: 32, message: "Invalid value" },
                                        max: { value: 46, message: "Invalid value" }
                                    })} /><div style={{ color: "red" }}>
                                        {errors['shoe_size'] && errors['shoe_size'].message}
                                    </div></Col>

                            </Row>
                        </>
                        }
                    </Container>
                    <button type="submit" className="btn btn-success" >Submit</button>
                </form>
            </div >
            {/* DEBUG: {JSON.stringify(curData)} */}
        </>
    );
});

export default BodyMeasurement;