import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import SubsetFabricSelect from '../fabricselect/SubsetFabricSelect';
import { DoModal, ModalContent } from "../../../utils/Modal";

import './selectparts.css'
import ButtonSelector from '../buttonselect/ButtonSelector';
import ButtonHoleSelector from '../buttonholeselect/ButtonHoleSelector';
import EmbroideryColorSelector from '../embroideryColor/EmbroideryColorSelect';
import PipingColorSelector from '../pipingColor/pipingColorSelect';


const SelectParts = (props) => {

    const [changedData, setChangedData] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,]);
    const noneFabric = { fabric_code: "P1", large_img: null, small_img: "./images/fabrics/P1.jpg", full_name: "P1", state: "1" };


    const [lastorder, setLastOrder] = useState([]);


    const [fabricsData, setFabricsData] = useState(props.fabricsData);
    const [curFabric, setCurFabric] = useState({ small_img: "" });
    const [curButton, setCurButton] = useState({ small_img: "/images/buttons/default.png", button_code: "Default" });

    const [curButtonHole, setCurButtonHole] = useState({ btnhole_id: "0", btnhole_code: "none", name: "Same as fabric", color: "#ffffff" });

    const [curPipingColor, setCurPipingColor] = useState({ pipingcolor_id: "0", fabric: "none", name: "None", color: "#ffffff" });

    const [curInsCollarFabric, setCurInsCollarFabric] = useState(noneFabric);
    const [curInsCuffFabric, setCurInsCuffFabric] = useState(noneFabric);

    const [isOpen, setIsopen] = useState(false);

    const [sameAsOldShirt, setSameAsOldShirt] = useState(false);
    const [showFabricInsCuffSelector, setShowInsCuffSelector] = useState(false);
    const [showFabricInsCollarSelector, setShowInsCollarSelector] = useState(false);
    const [showButtonSelector, setShowButtonSelector] = useState(false);
    const [showButtonHoleSelector, setShowButtonHoleSelector] = useState(false);
    const [showEmbColorSelector, setShowEmbColorSelector] = useState(false);
    const [showPipingColorSelector, setShowPipingColorSelector] = useState(false);

    const [quantity, setQuantity] = useState(1);
    const [notes, setNotes] = useState("");



    const fabricTempData = {};



    const bodyData = [
        { id: 1, name: '1. Fitted', name_vn: '', imgUrl: 'url(./images_shirt/body_slim.png)', imgBackUrl: 'url(./images_shirt/back_body_slim.png)' },
        { id: 2, name: '2. Semi-fitted', name_vn: '', imgUrl: 'url(./images_shirt/body_normal.png)', imgBackUrl: 'url(./images_shirt/back_body_normal.png)' },
        { id: 3, name: '3. Loose fit', name_vn: '', imgUrl: 'url(./images_shirt/body_loose.png)', imgBackUrl: 'url(./images_shirt/back_body_loose.png)' },
    ];
    const bodyData4 = { id: 4, name: '4. Measured from shirt', name_vn: '', imgUrl: 'url(./images_shirt/body_normal.png)', imgBackUrl: 'url(./images_shirt/back_body_normal.png)' };

    const [body, setBody] = useState(bodyData[0]);
    const [measure_type, setMeasureType] = useState(props.orderData.measurements.measure_type);

    // const backbodyData = [
    //     { id: "1", name: '1. Fitted', imgUrl: 'url(./images_shirt/body_slim.png)', imgBackUrl: 'url(./images_shirt/back_body_slim.png)' },
    //     { id: "2", name: '2. Semi-fitted', imgUrl: 'url(./images_shirt/body_normal.png)', imgBackUrl: 'url(./images_shirt/back_body_normal.png)' },
    //     { id: "3", name: '3. Loose fit', imgUrl: 'url(./images_shirt/body_loose.png)', imgBackUrl: 'url(./images_shirt/back_body_loose.png)' },
    //     { id: "4", name: '4. Copy from shirt', imgUrl: 'url(./images_shirt/body_slim.png)', imgBackUrl: 'url(././images_shirt/back_body_slim.png)' },
    // ];
    // // const [backbody, setBackBody] = useState(backbodyData[0]);

    const collarData = [
        { id: "1", name: '1. Classic', name_vn: '', imgUrl: 'url(./images_shirt/collars_classic.png)', imgDetail: './images_shirt/parts/collar_classic.png' },
        { id: "2", name: '2. Button down', name_vn: '', imgUrl: 'url(./images_shirt/collars_button_down.png)', imgDetail: './images_shirt/parts/collar_buttondown.png' },
        { id: "3", name: '3. Hidden button down', name_vn: '', imgUrl: 'url(./images_shirt/collar_hidden_buttondown.png)', imgDetail: './images_shirt/parts/collar_hidden_buttondown.png' },
        { id: "4", name: '4. Cut away', name_vn: '', imgUrl: 'url(./images_shirt/collars_cut_away.png)', imgDetail: './images_shirt/parts/collar_cutaway.png' },
        { id: "5", name: '5. Extreme cut away', name_vn: '', imgUrl: 'url(./images_shirt/collars_full_spread.png)', imgDetail: './images_shirt/parts/collar_extreme_cutaway.png' },
        { id: "6", name: '6. Wing', name_vn: '', imgUrl: 'url(./images_shirt/collars_wing_tip.png)', imgDetail: './images_shirt/parts/collar_wing.png' },
        { id: "7", name: '7. Round', name_vn: '', imgUrl: 'url(./images_shirt/collars_rounded.png)', imgDetail: './images_shirt/parts/collar_club.png' },
        { id: "8", name: '8. Mao', name_vn: '', imgUrl: 'url(./images_shirt/collars_mao.png)', imgDetail: './images_shirt/parts/collar_mao.png' },
        { id: "9", name: '9. Kent', name_vn: '', imgUrl: 'url(./images_shirt/collars_ainsley.png)', imgDetail: './images_shirt/parts/collar_narrow.png' },
    ];
    const [collar, setCollar] = useState(collarData[0]);

    const sleeveData = [
        { id: "1", name: '1. Long', name_vn: 'Long', imgUrl: 'url(./images_shirt/sleeves_arms_long.png)', imgBackUrl: 'url(././images_shirt/back_arms_long.png)' },
        { id: "2", name: '2. Short', name_vn: 'Short', imgUrl: 'url(./images_shirt/sleeves_arms_short.png)', imgBackUrl: 'url(././images_shirt/back_arms_short.png)' },
        { id: "3", name: '3. Short cuffed', name_vn: 'Short cuffed', imgUrl: 'url(./images_shirt/sleeves_arms_short_cuff.png)', imgBackUrl: 'url(././images_shirt/back_arms_short_cuff.png)' },
    ];
    const [sleeve, setSleeve] = useState(sleeveData[0]);

    var oldcuffssleeve = null;
    const cuffsSleeveData = [
        { id: "1", name: '1. Round 1 button', name_vn: '', imgUrl: 'url(./images_shirt/cuffs_single_button_rounded.png)', imgDetail: './images_shirt/parts/cuff_round_1.png', imgBackUrl: 'url(./images_shirt/back_cuffs_single_button_rounded.png)' },
        { id: "2", name: '2. Cut corner 1 button', name_vn: '', imgUrl: 'url(./images_shirt/cuffs_single_button_angled.png)', imgDetail: './images_shirt/parts/cuff_cutcorner_1.png', imgBackUrl: 'url(./images_shirt/back_cuffs_single_button_angled.png)' },
        { id: "3", name: '3. Square 1 button', name_vn: '', imgUrl: 'url(./images_shirt/cuffs_single_button_straight.png)', imgDetail: './images_shirt/parts/cuff_square_1.png', imgBackUrl: 'url(./images_shirt/back_cuffs_single_button_straight.png)' },
        { id: "4", name: '4. Round 2 buttons', name_vn: '', imgUrl: 'url(./images_shirt/cuffs_double_button_rounded.png)', imgDetail: './images_shirt/parts/cuff_round_2.png', imgBackUrl: 'url(./images_shirt/back_cuffs_double_button_rounded.png)' },
        { id: "5", name: '5. Cut corner 2 buttons', name_vn: '', imgUrl: 'url(./images_shirt/cuffs_double_button_angled.png)', imgDetail: './images_shirt/parts/cuff_square_2.png', imgBackUrl: 'url(./images_shirt/back_cuffs_double_button_angled.png)' },
        { id: "6", name: '6. Square 2 buttons', name_vn: '', imgUrl: 'url(./images_shirt/cuffs_double_button_straight.png)', imgDetail: './images_shirt/parts/cuff_square_2.png', imgBackUrl: 'url(./images_shirt/back_cuffs_double_button_straight.png)' },
        { id: "7", name: '7. Round double cuff', name_vn: '', imgUrl: 'url(./images_shirt/cuffs_french_rounded.png)', imgDetail: './images_shirt/parts/cuff_french_round.png', imgBackUrl: 'url(./images_shirt/back_cuffs_french_rounded.png)' },
        { id: "8", name: '8. Cut corner double cuff', name_vn: '', imgUrl: 'url(./images_shirt/cuffs_french_angled.png)', imgDetail: './images_shirt/parts/cuff_french_cutcorner.png', imgBackUrl: 'url(./images_shirt/back_cuffs_french_angled.png)' },
        { id: "9", name: '9. Square double cuff', name_vn: '', imgUrl: 'url(./images_shirt/cuffs_french_straight.png)', imgDetail: './images_shirt/parts/cuff_french_square.png', imgBackUrl: 'url(./images_shirt/back_cuffs_french_straight.png)' },
        { id: "10", name: '10.Neapolitan', name_vn: '', imgUrl: 'url(./images_shirt/cuffs_neapolitan.png)', imgDetail: './images_shirt/parts/cuff_neapolitan.png', imgBackUrl: 'url(./images_shirt/back_cuffs_neapolitan.png)' },
        { id: "11", name: '11.Elipse 1 button', name_vn: '', imgUrl: 'url(./images_shirt/cuffs_elipse.png)', imgDetail: './images_shirt/parts/cuffs_elipse.png', imgBackUrl: 'url(./images_shirt/back_cuffs_elipse.png)' },
    ];
    const [cuffssleeve, setCuffsSleeve] = useState(cuffsSleeveData[0]);


    const frontData = [
        { id: "1", name: '1. Normal placket', name_vn: 'Thường', imgUrl: 'url(./images_shirt/front_plackets.png)' },
        { id: "2", name: '2. Box placket', name_vn: 'Box', imgUrl: 'url(./images_shirt/front_buttons.png )' },
        { id: "3", name: '3. Hidden button rail', name_vn: 'Nẹp ẩn', imgUrl: 'url(./images_shirt/front_placket_hidden.png )' },
    ];
    const [front, setFront] = useState(frontData[0]);

    const pocketsData = [
        { id: "1", name: '1. None', name_vn: 'Không', imgUrl: 'url()' },
        { id: "2", name: '2. Classic', name_vn: 'Classic', imgUrl: 'url(./images_shirt/pocket_classic.png)' },
        { id: "3", name: '3. Classic - 2 pockets', name_vn: 'Classic 2 bên', imgUrl: 'url(./images_shirt/pocket_classic_2.png)' },
        { id: "4", name: '4. Pointed', name_vn: 'Diamond', imgUrl: 'url(./images_shirt/pocket_pointed.png)' },
        { id: "5", name: '5. Pointed - 2 pockets', name_vn: 'Diamond 2 bên', imgUrl: 'url(./images_shirt/pocket_pointed_2.png)' },
        { id: "6", name: '6. Angular', name_vn: 'Vát góc', imgUrl: 'url(./images_shirt/pocket_angular.png)' },
        { id: "7", name: '7. Angular - 2 pockets', name_vn: 'Vát góc 2 bên', imgUrl: 'url(./images_shirt/pocket_angular_2.png)' },
        { id: "8", name: '8. Square', name_vn: 'Vuông', imgUrl: 'url(./images_shirt/pocket_square.png)' },
        { id: "9", name: '9. Square - 2 pockets', name_vn: 'Vuông 2 bên', imgUrl: 'url(./images_shirt/pocket_square_2.png)' },
        { id: "10", name: '10. Box pleat - 2 pockets', name_vn: 'Ly hộp 2 bên', imgUrl: 'url(./images_shirt/pocket_box_pleat.png)' },
        { id: "11", name: '11. Inverted pleat - 2 pockets', name_vn: 'Ly súp 2 bên', imgUrl: 'url(./images_shirt/pocket_inverted_pleat.png)' },
    ];
    const [pockets, setPockets] = useState(pocketsData[0]);

    const flapData = [
        { id: "1", name: '1. None', name_vn: '', imgUrl: 'url()' },
        { id: "2", name: '2. Square flaps', name_vn: 'Vuông không cúc', imgUrl: 'url(./images_shirt/flap_square.png)' },
        { id: "3", name: '3. Angular flaps', name_vn: 'Nhọn không cúc', imgUrl: 'url(./images_shirt/flap_angular.png)' },
        { id: "4", name: '4. Pointed flaps', name_vn: 'Vát góc không cúc', imgUrl: 'url(./images_shirt/flap_pointed.png)' },
        { id: "5", name: '5. Square flaps with buttons', name_vn: 'Vuông có cúc', imgUrl: 'url(./images_shirt/flap_square_buttons.png)' },
        { id: "6", name: '6. Angular flaps with buttons', name_vn: 'Nhọn có cúc', imgUrl: 'url(./images_shirt/flap_angular_buttons.png)' },
        { id: "7", name: '7. Pointed flaps with buttons', name_vn: 'Vát góc có cúc', imgUrl: 'url(./images_shirt/flap_pointed_buttons.png)' },
    ];
    const [flap, setFlap] = useState(flapData[0]);


    const [epaulettes, setEpaulettes] = useState({ name: 'No', imgUrl: 'no' });

    const [whiteCollarCuff, setWhiteCollarCuff] = useState({ id: "1", name: '1. None', imgUrl: 'url()' });
    const whiteCollarCuffData = [
        { id: "1", name: '1. None', name_vn: '', imgUrl: 'url()' },
        { id: "2", name: '2. White Collar & Cuffs', name_vn: 'Toàn bộ Cổ & MS (P1)', imgUrl: 'url()' },
        { id: "3", name: '3. White Collar Only', name_vn: 'Toàn bộ cổ (P1)', imgUrl: 'url()' },
        { id: "4", name: '4. White Cuffs Only', name_vn: 'Toàn bộ MS (P1)', imgUrl: 'url()' },
    ];

    const [removeableStay, setRemoveableStay] = useState(false);
    const [leftWristPlus, setLeftWristPlus] = useState(false);
    const [rightWristPlus, setRightWristPlus] = useState(false);


    const backPleatsData = [
        { id: "1", name: '1. None', name_vn: '', imgUrl: 'url()', imgBackUrl: '' },
        { id: "2", name: '2. Center pleats', name_vn: 'Ly hộp', imgUrl: 'url()', imgBackUrl: 'url(./images_shirt/back_pleat_center.png)' },
        { id: "3", name: '3. Side pleats', name_vn: 'Ly 2 bên', imgUrl: 'url()', imgBackUrl: 'url(./images_shirt/back_pleat_side.png)' },
    ];
    const [backPleats, setBackPleats] = useState(backPleatsData[0]);
    const [splitYoke, setSplitYoke] = useState(false);
    const [untuckedShirt, setUntuckedShirt] = useState(false);

    const hemSideData = [
        { id: 1, name: '1. Round', name_vn: 'Round', imgUrl: 'url()' },
        { id: 2, name: '2. Round with tab', name_vn: 'Round + cá sườn', imgUrl: 'url()' },
        { id: 3, name: '3. Straight', name_vn: 'Thẳng', imgUrl: 'url()' },
        { id: 4, name: '4. Side vent', name_vn: 'Xẻ + đáp', imgUrl: 'url()' },
    ];
    const [hemSide, setHemSide] = useState(hemSideData[0]);


    const insideCollCuffData = [
        { id: "1", name: '1. None', name_vn: '', imgUrl: 'url()' },
        { id: "2", name: '2. Inside both Collar & Cuffs', name_vn: '', imgUrl: 'url()' },
        { id: "3", name: '3. Inside Collar only', name_vn: '', imgUrl: 'url()' },
        { id: "4", name: '4. Inside Cuff only', name_vn: '', imgUrl: 'url()' },
    ];
    const [insideCollCuff, setInsideCollCuff] = useState(insideCollCuffData[0]);

    const embroideryData = [
        { id: "1", name: 'No', imgUrl: 'url()' },
        { id: "2", name: 'Yes', imgUrl: 'url()' },
    ];
    const [embroidery, setEmbroidery] = useState(embroideryData[0]);


    const [embroideryText, setEmbroideryText] = useState("");
    const [embroideryFont, setEmbroideryFont] = useState("1");
    const [embroideryPosition, setEmbroideryPosition] = useState("1");

    const [embroideryColor, setEmbroideryColor] = useState({ embcolor_id: "0", embcolor_code: "none", name: "Same as fabric", color: "#ffffff" });


    const auth = useAuth();
    if (auth.user === null || auth.user === undefined) {
        auth.logOut();
        // return;
    }

    const curData = props.orderData;

    // console.log("curFabricData=", curFabricData);


    const showModal = () => setIsopen((prev) => !prev);

    const changeDataAt = (idx) => {
        const newData = [...changedData];
        if (idx < newData.length) {
            newData[idx] = true;
            setChangedData(newData);
        }
    }

    const bodySelect = (evt, evKey) => {

        bodyData.find((element) => {
            if (element.id == evKey.target.id) {
                if (element.id === 1 && hemSide.id === 3) { // Neu body la fitted thi khong co hemSide=3 - Straight
                    setHemSide(hemSideData[0]);
                }
                if (element.id === 1) { // Neu fitted thi khong co BackPleats
                    setBackPleats(backPleatsData[0]);
                }
                let h = selectHemAndBody(element.id, hemSide.id);
                if (h) {
                    if (h.imgUrl != '') {
                        setBody({ id: element.id, name: element.name, imgUrl: h.imgUrl, imgBackUrl: h.imgBackUrl })
                    } else {
                        setBody(element);
                    }
                } else {
                    setBody(element);
                }
                changeDataAt(0);

                return true;
            }
            return false;
        })
    };

    const collarSelect = (evt, evKey) => {

        collarData.find((element) => {
            if (element.id === evKey.target.id) {
                setCollar(element);
                changeDataAt(1);
                return true;
            }
            return false;
        })
    };


    const sleeveSelect = (evt, evKey) => {
        sleeveData.find((element) => {
            if (element.id === evKey.target.id) {
                setSleeve(element);
                if (element.id === "1") {
                    if (oldcuffssleeve != null) {
                        if (oldcuffssleeve.id === 0) {
                            // Truong hop cuff cũ là None
                            setCuffsSleeve(cuffsSleeveData[0]);
                        } else {
                            setCuffsSleeve(oldcuffssleeve);
                        }
                    } else {
                        setCuffsSleeve(cuffsSleeveData[0]);
                    }
                } else {
                    oldcuffssleeve = cuffssleeve;
                    setCuffsSleeve({ id: 0, name: 'None', imgUrl: '', imgDetail: '', imgBackUrl: '' });
                }
                changeDataAt(2);
                return true;
            }
            return false;
        })
    };

    const cuffssleeveSelect = (evt, evKey) => {
        cuffsSleeveData.find((element) => {
            if (element.id === evKey.target.id) {
                setCuffsSleeve(element);
                changeDataAt(3);
                return true;
            }
            return false;
        })

    };
    const frontSelect = (evt, evKey) => {
        frontData.find((element) => {
            if (element.id === evKey.target.id) {
                setFront(element);
                changeDataAt(4);
                return true;
            }
            return false;
        })
    };
    const pocketsSelect = (evt, evKey) => {
        //setPockets(evKey.target.text)
        pocketsData.find((element) => {
            if (element.id === evKey.target.id) {
                setPockets(element);
                changeDataAt(5);

                if (evKey.target.id === "1" || evKey.target.id === "2" || evKey.target.id === "4" ||
                    evKey.target.id === "6" || evKey.target.id === "8") {
                    setFlap(flapData[0]);

                }
                return true;
            }
            return false;
        })

    };

    const flapSelect = (evt, evKey) => {
        flapData.find((element) => {
            if (element.id === evKey.target.id) {
                setFlap(element);
                changeDataAt(6);
                return true;
            }
            return false;
        })
    };

    const epaulettesSelect = (evt, evKey) => {
        if (evKey.target.id === "1") {
            setEpaulettes({ name: 'No', imgUrl: '', imgBackUrl: '' })
        } else {
            setEpaulettes({ name: 'Yes', imgUrl: 'url(./images_shirt/epaulettes.png)', imgBackUrl: 'url(./images_shirt/back_epaulettes.png)' })
            //setEpaulettes({ name: 'Yes', imgUrl: '', imgBackUrl: '' })
        }
        changeDataAt(7);
    };

    const whiteCollarCuffSelect = (evt, evKey) => {

        whiteCollarCuffData.find((element) => {
            if (element.id === evKey.target.id) {

                setWhiteCollarCuff(element);
                changeDataAt(8);

                if (element.id === '1') {
                    setCurInsCollarFabric(curFabric);
                    setCurInsCuffFabric(curFabric);
                } else if (element.id === '2') {
                    setCurInsCollarFabric(noneFabric);
                    setCurInsCuffFabric(noneFabric);
                } else if (element.id === '3') {
                    setCurInsCollarFabric(noneFabric);
                    setCurInsCuffFabric(curFabric);

                } else if (element.id === '4') {
                    setCurInsCollarFabric(curFabric);
                    setCurInsCuffFabric(noneFabric);
                }
                return true;
            }
            return false;
        })

    };

    const backPleatsSelect = (evt, evKey) => {
        backPleatsData.find((element) => {
            if (element.id === evKey.target.id) {
                setBackPleats(element);
                changeDataAt(10);
                return true;
            }
            return false;
        })
    };

    const hemAndBodyArray = [
        { imgUrl: 'url(./images_shirt/body_slim.png)', imgBackUrl: 'url(./images_shirt/back_body_slim.png)' },
        { imgUrl: 'url(./images_shirt/body_slim_round_tab.png)', imgBackUrl: 'url(./images_shirt/back_body_slim_round_tab.png)' },
        { imgUrl: '', imgBackUrl: 'url(./images_shirt/)' },
        { imgUrl: 'url(./images_shirt/body_slim_side_vent.png)', imgBackUrl: 'url(./images_shirt/back_body_slim_side_vent.png)' },

        { imgUrl: 'url(./images_shirt/body_normal.png)', imgBackUrl: 'url(./images_shirt/back_body_normal.png)' },
        { imgUrl: 'url(./images_shirt/body_normal_round_tab.png)', imgBackUrl: 'url(./images_shirt/back_body_normal_round_tab.png)' },
        { imgUrl: 'url(./images_shirt/body_normal_straight.png)', imgBackUrl: 'url(./images_shirt/back_body_normal_straight.png)' },
        { imgUrl: 'url(./images_shirt/body_normal_side_vent.png)', imgBackUrl: 'url(./images_shirt/back_body_normal_straight.png)' },

        { imgUrl: 'url(./images_shirt/body_loose.png)', imgBackUrl: 'url(./images_shirt/back_body_loose.png)' },
        { imgUrl: 'url(./images_shirt/body_loose_round_tab.png)', imgBackUrl: 'url(./images_shirt/back_body_loose_round_tab.png)' },
        { imgUrl: 'url(./images_shirt/body_loose_straight.png)', imgBackUrl: 'url(./images_shirt/back_body_loose_straight.png)' },
        { imgUrl: 'url(./images_shirt/body_loose_side_vent.png)', imgBackUrl: 'url(./images_shirt/back_body_loose_straight.png)' },

        { imgUrl: 'url(./images_shirt/body_normal.png)', imgBackUrl: 'url(./images_shirt/back_body_normal.png)' },
        { imgUrl: 'url(./images_shirt/body_normal_round_tab.png)', imgBackUrl: 'url(./images_shirt/back_body_normal_round_tab.png)' },
        { imgUrl: 'url(./images_shirt/body_normal_straight.png)', imgBackUrl: 'url(./images_shirt/back_body_normal_straight.png)' },
        { imgUrl: 'url(./images_shirt/body_normal_side_vent.png)', imgBackUrl: 'url(./images_shirt/back_body_normal_straight.png)' },

    ];

    const selectHemAndBody = (bodyId, hemId) => {
        // console.log("DEBUG:", { bodyId, hemId });
        // console.log("DEBUG:", (parseInt(bodyId) - 1) * 3 + parseInt(hemId) - 1);
        return hemAndBodyArray[(parseInt(bodyId) - 1) * 4 + parseInt(hemId) - 1];
    }

    const hemSideSelect = (evt, evKey) => {
        hemSideData.find((element) => {
            if (element.id == evKey.target.id) {
                setHemSide(element);
                changeDataAt(11);
                let h = selectHemAndBody(body.id, element.id);
                if (h) {
                    if (h.imgUrl != '') {
                        setBody({ id: body.id, name: body.name, imgUrl: h.imgUrl, imgBackUrl: h.imgBackUrl })
                    }
                }

                return true;
            }
            return false;
        })
    };

    const insideCollCuffSelect = (evt, evKey) => {
        setInsideCollCuff(evKey.target.text);
        changeDataAt(9);
    };



    // const fabricInsideCCSelect = (evt, evKey) => {
    //     setFabricInsideCC(evKey.target.text);
    //     changeDataAt(11);
    // };
    const embroiderySelect = (evt, evKey) => {
        embroideryData.find((element) => {
            if (element.id === evKey.target.id) {
                setEmbroidery(element);
                changeDataAt(12);
                return true;
            }
            return false;
        })


    };

    const onSelectInsideCollar = (evt) => {
        if (whiteCollarCuff.id === "2" || whiteCollarCuff.id === "3") { // White Collar
            return;
        }
        setShowInsCollarSelector(true);
    }

    const onSelectorInsCollarClose = () => {
        setShowInsCollarSelector(false);
    }

    const onSelectInsideCollarFabric = (data, next) => {
        if (data.fabric_code === 'None') {
            setCurInsCollarFabric(curFabric);
        } else {
            setCurInsCollarFabric(data);
        }
        setShowInsCollarSelector(false);
    }


    const onSelectInsideCuff = (evt) => {
        if (whiteCollarCuff.id === "2" || whiteCollarCuff.id === "4") { // White Cuff
            return;
        }
        setShowInsCuffSelector(true);
    }

    const onSelectorInsCuffClose = () => {
        setShowInsCuffSelector(false);
    }

    const onSelectInsideCuffFabric = (data, next) => {

        if (data.fabric_code === 'None') {
            setCurInsCuffFabric(curFabric);
        } else {
            setCurInsCuffFabric(data);
        }
        setShowInsCuffSelector(false);
    }

    const onShowButtonSelector = (evt) => {
        setShowButtonSelector(true);
    }

    const onButtonSelectorClose = () => {
        setShowButtonSelector(false);
    }

    const onSelectButton = (data, next) => {
        setCurButton(data);
        setShowButtonSelector(false);
    }

    const onShowButtonHoleSelector = (evt) => {
        setShowButtonHoleSelector(true);
    }

    const onButtonHoleSelectorClose = () => {
        setShowButtonHoleSelector(false);
    }

    const onSelectButtonHole = (data, next) => {
        setCurButtonHole(data);
        setShowButtonHoleSelector(false);
    }

    const onShowEmbColorSelector = (evt) => {
        setShowEmbColorSelector(true);
    }

    const onEmbColorSelectorClose = () => {
        setShowEmbColorSelector(false);
    }

    const onSelectEmbColor = (data, next) => {
        setEmbroideryColor(data);
        setShowEmbColorSelector(false);
    }

    const onShowPipingColorSelector = (evt) => {
        setShowPipingColorSelector(true);
    }

    const onPipingColorSelectorClose = () => {
        setShowPipingColorSelector(false);
    }

    const onSelectPipingColor = (data, next) => {
        setCurPipingColor(data);
        setShowPipingColorSelector(false);
    }


    const handleEmbroideryText = (event) => {
        setEmbroideryText(event.target.value);
    }

    const handleEmbroideryFont = (event) => {
        setEmbroideryFont(event.target.value);
    }
    const handleEmbroideryPosition = (event) => {
        setEmbroideryPosition(event.target.value);
    }
    const handleEmbroideryColor = (event) => {
        console.log(event.target);
    }

    const handleRemoveableStay = (event) => {

        setRemoveableStay(event.target.checked);
    }

    const handleSplitYoke = (event) => {
        //  console.log(event.target)
        setSplitYoke(event.target.checked);
    }

    const handleUntuckedShirt = (event) => {
        setUntuckedShirt(event.target.checked);
    }

    const handleLeftWristPlus = (event) => {
        setLeftWristPlus(event.target.checked);
        if (event.target.checked & rightWristPlus) {
            setRightWristPlus(false);
        }
    }
    const handleRightWristPlus = (event) => {
        setRightWristPlus(event.target.checked);
        if (event.target.checked & leftWristPlus) {
            setLeftWristPlus(false);
        }
    }


    const handleQuantityChange = (event) => {

        setQuantity(event.target.value);
    }

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    }


    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    const handleSubmit = (evt) => {

        evt.preventDefault();

        if (!isNumeric(quantity) || parseInt(quantity) < 1) {
            alert("The quantity not valid")
            setQuantity("1");
            return;
        }


        let temp = { ...props.orderData };


        // Process shirtMeasure

        let shirtMeasure = {};
        // console.debug("Before : ", temp.order_infos[temp.cur_order]['measurements']);
        let isKL = (curFabric.fabric_code.charAt(0) === 'K') || (curFabric.fabric_code.charAt(0) === 'J');


        let calIdx = -1; // Dinh vi delta can add
        switch (body.id) {
            case 1: //Fitted
                calIdx = 0 + (isKL ? 1 : 0);
                break;
            case 2: // Semi-Fitted
                calIdx = 2 + (isKL ? 1 : 0);
                break;
            case 3: // Loose
                calIdx = 4 + (isKL ? 1 : 0);
                break;
        }

        let measure = temp.measurements;
        let deltaset = auth.user.config['deltaset'];
        // console.debug("calIdx =", calIdx);
        // console.debug("measure =", measure);

        if (measure.measure_type === '2') {
            calIdx = -1;
        }

        if (calIdx >= 0) {
            // console.debug("delta =", deltaset[calIdx]);
            shirtMeasure = {
                name: deltaset[calIdx].name,
                collar: parseInt(measure.neck) + parseInt(deltaset[calIdx].collar),
                chest: parseInt(measure.chest) + parseInt(deltaset[calIdx].chest),
                waist: parseInt(measure.waist) + parseInt(deltaset[calIdx].waist),
                hip: parseInt(measure.hip) + parseInt(deltaset[calIdx].hip),
                shoulders: parseInt(measure.shoulders) + parseInt(deltaset[calIdx].shoulder),
                upper_arm: parseInt(measure.upper_arm) + parseInt(deltaset[calIdx].upper_arm),
                wrist: parseInt(measure.wrist) + parseInt(deltaset[calIdx].cuff),
                sleeve_length: parseInt(measure.arm_length) + parseInt(deltaset[calIdx].sleeve_length),
                back_length_business: parseInt(measure.back_length) + parseInt(deltaset[calIdx].back_len_business),
                back_length_casual: parseInt(measure.back_length) + parseInt(deltaset[calIdx].back_len_casual),
            }
            // { id: "neck", img_src: "measure-collar.png" },
            // { id: "chest", img_src: "measure-chest.png" },
            // { id: "waist", img_src: "measure-waist.png" },
            // { id: "hips", img_src: "measure-seat.png" },
            // { id: "shoulders", img_src: "measure-shoulder.png" },
            // { id: "arm_length", img_src: "measure-sleeve.png" },
            // { id: "upper_arm", img_src: "measure-upper_arm.png" },
            // { id: "wrist", img_src: "measure-wrist.png" },
            // { id: "back_length", img_src: "measure-back.png" },

        } else { // calIdx = -1 -> Lay theo so do shirt
            //shirtMeasure = temp.order_infos[temp.cur_order]['measurements'];
            console.debug("delta = None");
            shirtMeasure = {
                name: "none",
                collar: parseInt(measure.neck),
                chest: parseInt(measure.chest),
                waist: parseInt(measure.waist),
                hip: parseInt(measure.hip),
                shoulders: parseInt(measure.shoulders),
                upper_arm: parseInt(measure.upper_arm),
                wrist: parseInt(measure.wrist),
                sleeve_length: parseInt(measure.arm_length),
                back_length_business: parseInt(measure.back_length),
                back_length_casual: parseInt(measure.back_length),
            }
        }


        // console.debug("After : ", shirtMeasure);


        // Xu ly check theo don hang cu:

        let checkPattern = { olddata: null, check: null };
        if (lastorder.length > 0) {
            //    console.log('Lastorders:', lastorder);

            let lo = lastorder.find((lo) => lo.body === body.id);
            //    console.log("LO1:", lo);

            if (lo == null) {
                lo = lastorder[0];
            }

            //    console.log("LO2:", lo);

            if (lo) {
                let data_json = JSON.parse(lo.data_json);
                //        console.log("olddata:", data_json);
                if (data_json.measurements) {
                    let olddata = data_json.measurements;

                    let check = {};
                    check['collar'] = (olddata.collar === shirtMeasure.collar);
                    check['chest'] = (olddata.chest === shirtMeasure.chest);
                    check['waist'] = (olddata.waist === shirtMeasure.waist);
                    check['hip'] = (olddata.hip === shirtMeasure.hip);
                    check['shoulders'] = (olddata.shoulders === shirtMeasure.shoulders);
                    check['upper_arm'] = (olddata.upper_arm === shirtMeasure.upper_arm);
                    check['wrist'] = (olddata.wrist === shirtMeasure.wrist);
                    check['sleeve_length'] = (olddata.sleeve_length === shirtMeasure.sleeve_length);
                    check['back_length_business'] = (olddata.back_length_business === shirtMeasure.back_length_business);
                    check['back_length_casual'] = (olddata.back_length_casual === shirtMeasure.back_length_casual);

                    checkPattern = { olddata, check, order_id: lo.order_id, order_prefix: lo.order_prefix, item_id: lo.item_id };
                }
            }

        }


        //temp.order_infos[temp.cur_order]['shirtmeasure'] = shirtMeasure;



        fetch("/api/searchpattern", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({
                collar: shirtMeasure.collar,
                chest: shirtMeasure.chest,
                waist: shirtMeasure.waist,
                hip: shirtMeasure.hip,
                shoulder: shirtMeasure.shoulders,
                upper_arm: shirtMeasure.upper_arm,
                wrist: shirtMeasure.wrist,
                sleeve: shirtMeasure.sleeve_length,

                back_length: shirtMeasure.back_length_business,
                back_length_casual: shirtMeasure.back_length_casual,
            }),
        })
            .then((response) => response.json())
            .then((rowData) => {
                if (rowData["result"]) {
                    if (rowData["result"] === "timeout") {
                        auth.logOut();
                    } else {
                        alert("Error when query DB");
                    }
                } else {
                    //          console.log("Return Pattern:", rowData[0]);

                    let confData = {
                        body: body,
                        collar: collar,
                        cuffssleeve: cuffssleeve,
                        sleeve: sleeve,
                        front: front,
                        pockets: pockets,
                        flap: flap,
                        epaulettes: epaulettes,
                        whitecollarcuff: whiteCollarCuff,
                        backPleats: backPleats,
                        hemSide: hemSide,
                        insideCollCuff: insideCollCuff,

                        embroidery: embroidery,
                        embroideryText: (embroidery.id === "2" ? embroideryText : ""),
                        embroideryColor: (embroidery.id === "2" ? embroideryColor : { embcolor_id: "0", embcolor_code: "none", name: "Same as fabric", color: "#ffffff" }),
                        embroideryFont: (embroidery.id === "2" ? embroideryFont : ""),
                        embroideryPosition: (embroidery.id === "2" ? embroideryPosition : ""),

                        removeableStay: removeableStay,
                        leftWristPlus: leftWristPlus,
                        rightWristPlus: rightWristPlus,

                        insCollarFabric: curInsCollarFabric,
                        insCuffFabric: curInsCuffFabric,
                        button: curButton,
                        buttonHoleColor: curButtonHole,
                        splitYoke: splitYoke,
                        untuckedShirt: untuckedShirt,
                        pipingColor: curPipingColor,

                        shirtmeasure: shirtMeasure,

                        shirtPattern: rowData[0],

                        checkOldPattern: checkPattern,

                    }

                    temp.order_infos[temp.cur_order]['shirtConfig'] = confData;
                    temp.order_infos[temp.cur_order]['num_of_shirt'] = quantity;
                    temp.order_infos[temp.cur_order]['notes'] = notes;

                    //        console.log("Config Data: ", temp);
                    props.updateShirtConf(temp, true);

                }
                // console.log(rowData);
            })
            .catch((error) => { console.log(error); alert("Error call API"); });




    }


    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        setCurFabric(fabricsData.find(obj => obj['fabric_code'] === props.orderData.order_infos[props.orderData.cur_order]['fabric_code']));
        setCurInsCollarFabric(fabricsData.find(obj => obj['fabric_code'] === props.orderData.order_infos[props.orderData.cur_order]['fabric_code']));
        setCurInsCuffFabric(fabricsData.find(obj => obj['fabric_code'] === props.orderData.order_infos[props.orderData.cur_order]['fabric_code']));
        setMeasureType(props.orderData.measurements.measure_type);


        if (measure_type === "2") {
            setBody(bodyData4)
        }


        // console.debug("measure_type=", props.orderData.measurements['measure_type']);

        fetch("/api/lastorder/" + curData.client_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                console.debug("curData.client_id=", curData.client_id);
                console.debug("DEBUG: fetch =", rowData);
                if (rowData["result"]) { auth.logOut(); return }

                setLastOrder(rowData);
            })
            .catch((error) => console.log(error));



        if (props.orderData.order_infos) {
            let shirtConfig = props.orderData.order_infos[props.orderData.cur_order]['shirtConfig'];
            if (shirtConfig) {
                setBody(shirtConfig['body']);
                //  setBackBody(shirtConfig['backbody']);
                setCollar(shirtConfig['collar']);
                setCuffsSleeve(shirtConfig['cuffssleeve']);
                setFront(shirtConfig['front']);
                setPockets(shirtConfig['pockets']);
                setFlap(shirtConfig['flap']);
                setEpaulettes(shirtConfig['epaulettes']);
                setWhiteCollarCuff(shirtConfig['whitecollarcuff']);
                setBackPleats(shirtConfig['backPleats']);
                setHemSide(shirtConfig['hemSide']);
                setInsideCollCuff(shirtConfig['insideCollCuff']);
                // setFabricInsideCC(shirtConfig['fabricInsideCC']);
                setEmbroidery(shirtConfig['embroidery']);

                setEmbroideryColor(shirtConfig['embroideryColor']);
                setEmbroideryText(shirtConfig['embroideryText']);
                setEmbroideryFont(shirtConfig['embroideryFont']);
                setEmbroideryPosition(shirtConfig['embroideryPosition']);



                setRemoveableStay(shirtConfig['removeableStay']);
                setLeftWristPlus(shirtConfig['leftWristPlus']);
                setRightWristPlus(shirtConfig['rightWristPlus']);

                setCurButton(shirtConfig['button']);
                setCurButtonHole(shirtConfig['buttonHoleColor'])
                setSplitYoke(shirtConfig['splitYoke']);
                setUntuckedShirt(shirtConfig['untuckedShirt']);

                setCurPipingColor(shirtConfig['pipingColor']);

                setQuantity(props.orderData.order_infos[props.orderData.cur_order]['num_of_shirt']);
                setNotes(props.orderData.order_infos[props.orderData.cur_order]['notes']);



                if (props.orderData.measurements['measure_type'] === "3") {
                    // console.debug("userEffice, sameasoldshirt=", true);
                    setSameAsOldShirt(true);
                    setBody(bodyData4);
                    // setBackBody(backbodyData[3]);
                }

                if (shirtConfig['insCollarFabric']) {
                    if (shirtConfig.insCollarFabric['fabric_code'] === "None") {
                        setCurInsCollarFabric(noneFabric);
                    } else {
                        setCurInsCollarFabric(fabricsData.find(obj => obj['fabric_code'] === shirtConfig.insCollarFabric['fabric_code']));
                    }
                }
                if (shirtConfig['insCuffFabric']) {
                    if (shirtConfig.insCuffFabric['fabric_code'] === "None") {
                        setCurInsCuffFabric(noneFabric);
                    } else {
                        setCurInsCuffFabric(fabricsData.find(obj => obj['fabric_code'] === shirtConfig.insCuffFabric['fabric_code']));
                    }
                }

            }
        }

    }, []);

    return (
        <>

            <form onSubmit={handleSubmit}>

                <Container className="p-3 ">

                    <Row style={{ width: "100%" }}>
                        <Col md="auto" xs={6}>
                            <table className="table  table-sm">
                                <thead className="table-dark">
                                    <tr>
                                        <th colSpan={2}>Choose what you need</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <DropdownButton id="dropdown-body" title="Body" size="sm" variant="secondary" onSelect={bodySelect} disabled={sameAsOldShirt}>

                                                {measure_type === "2" ?
                                                    <Dropdown.Item id={bodyData4.id} key={bodyData4.id}>{bodyData4.name}</Dropdown.Item>
                                                    :
                                                    bodyData.map((e) => (
                                                        <Dropdown.Item id={e.id} key={e.id}>{e.name}</Dropdown.Item>
                                                    ))
                                                }
                                            </DropdownButton></td>
                                        <td >  {changedData[0] === true ? <div style={{ color: "blue" }}> {body.name} </div> : <>{body.name}</>} </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <DropdownButton id="dropdown-collar" title="Collar" size="sm" variant="secondary" onSelect={collarSelect}>
                                                {collarData.map((e) => (
                                                    <Dropdown.Item id={e.id} key={e.id}>{e.name}</Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                        </td>
                                        <td>{changedData[1] === true ? <div style={{ color: "blue" }}> {collar.name} </div> : <>{collar.name}</>} </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <DropdownButton id="dropdown-sleeve" title="Sleeve" size="sm" variant="secondary" onSelect={sleeveSelect}>
                                                {sleeveData.map((e) => (
                                                    <Dropdown.Item id={e.id} key={e.id}>{e.name}</Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                        </td>
                                        <td>{changedData[2] === true ? <div style={{ color: "blue" }}> {sleeve.name} </div> : <>{sleeve.name}</>} </td>
                                    </tr>
                                    {sleeve.id === "1" && <>
                                        <tr>
                                            <td>
                                                <DropdownButton id="dropdown-cuffssleeve" title="Cuffs" size="sm" variant="secondary" onSelect={cuffssleeveSelect}>
                                                    {cuffsSleeveData.map((e) => (
                                                        <Dropdown.Item id={e.id} key={e.id}>{e.name}</Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </td>
                                            <td>{changedData[3] === true ? <div style={{ color: "blue" }}> {cuffssleeve.name} </div> : <>{cuffssleeve.name}</>} </td>
                                        </tr>
                                    </>}
                                    <tr>
                                        <td>
                                            <DropdownButton id="dropdown-front" title="Front Placket" size="sm" variant="secondary" onSelect={frontSelect}>
                                                {frontData.map((e) => (
                                                    <Dropdown.Item id={e.id} key={e.id}>{e.name}</Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                        </td>
                                        <td>{changedData[4] === true ? <div style={{ color: "blue" }}> {front.name} </div> : <>{front.name}</>} </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <DropdownButton id="dropdown-pockets" title="Pocket" size="sm" variant="secondary" onSelect={pocketsSelect}>
                                                {pocketsData.map((e) => (
                                                    <Dropdown.Item id={e.id} key={e.id}>{e.name}</Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                        </td>
                                        <td>{changedData[5] === true ? <div style={{ color: "blue" }}> {pockets.name} </div> : <>{pockets.name}</>} </td>
                                    </tr>
                                    {((pockets.id === "3" || pockets.id === "5" || pockets.id === "7" ||
                                        pockets.id === "9" || pockets.id === "10" || pockets.id === "11"
                                    ))
                                        && <><tr>
                                            <td>
                                                <DropdownButton id="dropdown-flap" title="Pocket Flaps" size="sm" variant="secondary" onSelect={flapSelect}>
                                                    {flapData.map((e) => (
                                                        <Dropdown.Item id={e.id} key={e.id}>{e.name}</Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </td>
                                            <td>{changedData[6] === true ? <div style={{ color: "blue" }}> {flap.name} </div> : <>{flap.name}</>} </td>
                                        </tr>
                                        </>}
                                    <tr>
                                        <td>
                                            <DropdownButton id="dropdown-epaulettes" title="Epaulettes" size="sm" variant="secondary" onSelect={epaulettesSelect}>
                                                <Dropdown.Item id="1" key="1">No</Dropdown.Item>
                                                <Dropdown.Item id="2" key="2">Yes</Dropdown.Item>
                                            </DropdownButton>
                                        </td>
                                        <td>{changedData[7] === true ? <div style={{ color: "blue" }}> {epaulettes.name} </div> : <>{epaulettes.name}</>} </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <DropdownButton id="dropdown-whitecollarcuff" title="White Collar & Cuffs" size="sm" variant="secondary" onSelect={whiteCollarCuffSelect}>
                                                {whiteCollarCuffData.map((e) => (
                                                    <Dropdown.Item id={e.id} key={e.id}>{e.name}</Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                        </td>
                                        <td>{changedData[8] === true ? <div style={{ color: "blue" }}> {whiteCollarCuff.name} </div> : <>{whiteCollarCuff.name}</>} </td>

                                    </tr>
                                    {(whiteCollarCuff.id === "1" && false) && <><tr>
                                        <td>
                                            <DropdownButton id="dropdown-insidecollcuff" title="Inside Collar & Cuffs" size="sm" variant="secondary" onSelect={insideCollCuffSelect}>
                                                {insideCollCuffData.map((e) => (
                                                    <Dropdown.Item id={e.id} key={e.id}>{e.name}</Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                        </td>
                                        <td>{changedData[9] === true ? <div style={{ color: "blue" }}> {insideCollCuff} </div> : <>{insideCollCuff}</>} </td>

                                    </tr></>}
                                    {(body.id !== 1) && <>
                                        <tr>
                                            <td>
                                                <DropdownButton id="dropdown-backpleats" title="Back yoke pleats" size="sm"
                                                    variant="secondary" onSelect={backPleatsSelect}>
                                                    {backPleatsData.map((e) => (
                                                        <Dropdown.Item id={e.id} key={e.id}>{e.name}</Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </td>
                                            <td>{changedData[10] === true ? <div style={{ color: "blue" }}> {backPleats.name} </div> : <>{backPleats.name}</>} </td>

                                        </tr></>}
                                    <tr>
                                        <td>
                                            <DropdownButton id="dropdown-hemside" title="Hem side" size="sm" variant="secondary" onSelect={hemSideSelect}>
                                                {hemSideData.map((e) => {
                                                    if (!(body.id === 1 && e.id === 3)) {
                                                        return (<Dropdown.Item id={e.id} key={e.id}>{e.name}</Dropdown.Item>)
                                                    }

                                                })}
                                            </DropdownButton>
                                        </td>
                                        <td>{changedData[11] === true ? <div style={{ color: "blue" }}> {hemSide.name} </div> : <>{hemSide.name}</>} </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <DropdownButton id="dropdown-embrcolor" title="Embroidery" size="sm" variant="secondary" onSelect={embroiderySelect}>
                                                {embroideryData.map((e) => (
                                                    <Dropdown.Item id={e.id} key={e.id}>{e.name}</Dropdown.Item>
                                                ))}

                                            </DropdownButton>
                                        </td>
                                        <td>{changedData[12] === true ? <div style={{ color: "blue" }}> {embroidery.name} </div> : <>{embroidery.name}</>} </td>
                                    </tr>
                                </tbody>
                            </table>

                        </Col>
                        <Col xs={6}>
                            <div id="" style={{ overflow: "scroll", height: "800px", width: "800px", scrollbarWidth: "none" }}>
                                <div className='container detail-box '>
                                    <div className='row m-1 p-1' style={{ minWidth: "740px" }}>
                                        <div className='col-md-6 '>
                                            <div id="shirtView" style={{ width: "350px", height: "337px", display: "blocks" }}>
                                                <div id="shirtBody" style={{
                                                    backgroundImage: body.imgUrl,
                                                    backgroundPosition: "initial", backgroundRepeat: "initial", border: "solid black 1px"
                                                }}>
                                                    <div id="shirtArms" style={{ background: sleeve.imgUrl }}>
                                                        <div id="shirtPlackets" style={{ background: front.imgUrl }} >

                                                            <div id="shirtPocketFlaps">
                                                                <div id="shirtButtons" style={{ background: "none" }}>
                                                                    <div id="shirtCollars" style={{ background: collar.imgUrl }}>
                                                                        <div id="shirtTopButton" >
                                                                            <div id="shirtEpaulettes" style={{ background: epaulettes.imgUrl }}>
                                                                                <div id="shirtPlacketButton">
                                                                                    <div id="shirtPockets" style={{ background: pockets.imgUrl }} >
                                                                                        <div id="shirtFlaps" style={{ background: flap.imgUrl }}>
                                                                                            <div id="shirtCuffs" style={{ background: cuffssleeve.imgUrl, width: "350px", height: "337px" }}>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div></div>
                                        <div className='col-md-6  '>
                                            <div id="shirtView" style={{ width: "350px", height: "337px", display: "blocks" }}>
                                                <div id="bshirtBody" style={{
                                                    backgroundImage: body.imgBackUrl,
                                                    backgroundPosition: "initial",
                                                    backgroundRepeat: "initial",
                                                    border: "solid black 1px"
                                                }}>
                                                    <div id="bshirtArms" style={{ background: sleeve.imgBackUrl }}>
                                                        <div id="bshirtPlackets" style={{ background: front.imgBackUrl }} >

                                                            <div id="bshirtPocketFlaps">
                                                                <div id="bshirtButtons" style={{ background: "none" }}>
                                                                    <div id="bshirtCollars" style={{ background: collar.imgBackUrl }}>
                                                                        <div id="bshirtTopButton" >
                                                                            <div id="bshirtEpaulettes" style={{ background: epaulettes.imgBackUrl }}>
                                                                                <div id="bshirtFlaps" style={{ background: splitYoke ? 'url(./images_shirt/back_yoke_split.png)' : '' }}>
                                                                                    <div id="bshirtPlacketButton">
                                                                                        <div id="bbackPleats" style={{ background: backPleats.imgBackUrl }} >
                                                                                            <div id="bshirtCuffs" style={{ background: cuffssleeve.imgBackUrl, width: "350px", height: "337px" }}>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="m-2" />
                                        <div className='row'>
                                            <div className='col'> <strong>Main fabric: {curFabric.fabric_code}</strong>


                                                <div className='d-flex justify-content-center'>

                                                    <DoModal onOpen={showModal}>

                                                        <img src={curFabric.small_img} alt="" style={{
                                                            width: "100px", height: "100px",
                                                            margin: "auto",
                                                            display: "blocks", borderRadius: "10%", boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                                                            cursor: "pointer"
                                                        }} />
                                                    </DoModal>
                                                    {isOpen && (
                                                        <ModalContent onClose={() => setIsopen(false)} style={{ margin: "auto", width: "800px", maxWidth: "800px" }}>
                                                            <img src={curFabric.small_img} alt="" style={{ width: "320px", height: "320px", margin: "auto" }} />
                                                        </ModalContent>
                                                    )}

                                                </div>

                                            </div>
                                            <div className='col'>
                                                <div className="row m-2">
                                                    <div className='col-6'>
                                                        <label className="form-check-label" style={{ textAlign: "end", float: "right" }}>Buttons:</label>
                                                    </div>
                                                    <div className='col-6'>
                                                        <img src={curButton.small_img} className="img-fluid" alt="Button"
                                                            onClick={onShowButtonSelector}
                                                            style={{
                                                                width: "50px", display: "block",
                                                                cursor: "pointer"
                                                            }}
                                                        /><span>
                                                            {curButton.button_code}</span>

                                                        <Modal show={showButtonSelector} onHide={onButtonSelectorClose} dialogClassName="button-modal">
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Button selector</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <ButtonSelector selectButton={onSelectButton} />
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={onButtonSelectorClose}>
                                                                    Close
                                                                </Button>

                                                            </Modal.Footer>
                                                        </Modal>
                                                    </div>
                                                </div>
                                                <div className="row m-2">
                                                    <div className='col-6'>
                                                        <label className="form-check-label" style={{ textAlign: "end", float: "right" }}>Buttonhole color:</label>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className="color-item"><div title={curButtonHole.name + " (" + curButtonHole.btnhole_code + ")"} className="color-img" style={{ backgroundColor: curButtonHole.color }}
                                                            onClick={onShowButtonHoleSelector}
                                                        ></div></div> &nbsp;
                                                        {curButtonHole.name + " (" + curButtonHole.btnhole_code + ")"}
                                                        <Modal show={showButtonHoleSelector} onHide={onButtonHoleSelectorClose} dialogClassName="buttonhole-modal">
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Button Hole Color selector</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <ButtonHoleSelector selectButtonHole={onSelectButtonHole} />
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={onButtonHoleSelectorClose}>
                                                                    Close
                                                                </Button>

                                                            </Modal.Footer>
                                                        </Modal>
                                                    </div>

                                                </div>
                                                <div className="row m-2">
                                                    <div className='col-6'>
                                                        <label className="form-check-label" style={{ textAlign: "end", float: "right" }}>Split yoke:</label>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className="form-check-input" type="checkbox" id="splitYoke" name="splitYoke" checked={splitYoke} style={{ marginLeft: "10px" }}
                                                            onChange={handleSplitYoke} />
                                                        &nbsp;</div>
                                                </div>
                                                <div className="row m-2">
                                                    <div className='col-6'>
                                                        <label className="form-check-label" style={{ textAlign: "end", float: "right" }}>Untucked shirt:</label>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className="form-check-input" type="checkbox" id="untuckedshirt" name="untuckedshirt" checked={untuckedShirt} style={{ marginLeft: "10px" }}
                                                            onChange={handleUntuckedShirt} />
                                                        &nbsp;</div>
                                                </div>

                                            </div>
                                        </div>
                                        <hr className="m-2" />
                                        <div className='row m-1 p-1'>
                                            <div className='col-md-6 border detail-item'>

                                                <div className="row">
                                                    <div className='col-5'>
                                                        <div className="form-check p-6 mt-3" style={{ whiteSpace: "nowrap" }}>
                                                            <strong>Inside collar: {curInsCollarFabric.fabric_code}</strong>
                                                            <img src={curInsCollarFabric.small_img} className="img-fluid inside-fabric" alt="Collar Fabric"
                                                                onClick={onSelectInsideCollar}
                                                            />
                                                            <Modal show={showFabricInsCollarSelector} onHide={onSelectorInsCollarClose} dialogClassName="my-modal">
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Inside collar fabric selector</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <SubsetFabricSelect orderData={fabricTempData} fabricsData={fabricsData} selectFabric={onSelectInsideCollarFabric} />
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button variant="secondary" onClick={onSelectorInsCollarClose}>
                                                                        Close
                                                                    </Button>

                                                                </Modal.Footer>
                                                            </Modal>
                                                        </div>
                                                    </div>
                                                    <div className='col-7'>
                                                        <img src={collar.imgDetail} className="img-fluid" alt="Collar" style={{ width: "100px", height: "100px", display: "block", margin: "auto", span: "20px" }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-check p-6 mt-3" style={{ whiteSpace: "nowrap" }}>
                                                        <strong>Piping color:</strong>
                                                    </div>
                                                </div>
                                                <div className="row m-auto" style={{ whiteSpace: "nowrap", width: "100%" }}>
                                                    {/* <div className="color-item active"><div title="" className="color-img" style={{ backgroundImage: `url(./static/images/pattern-ban.png)` }}></div></div>
                                                    <div className="color-item"><div title="White" className="color-img" style={{ backgroundColor: `rgb(255, 255, 255)` }}></div></div>
                                                    <div className="color-item"><div title="Light blue" className="color-img" style={{ backgroundColor: `rgb(219, 240, 255)` }}></div></div>
                                                    <div className="color-item"><div title="Grey" className="color-img" style={{ backgroundColor: `rgb(204, 204, 204)` }}></div></div>
                                                    <div className="color-item"><div title="Dark Blue" className="color-img" style={{ backgroundColor: `rgb(81, 63, 124)` }}></div></div>
                                                    <div className="color-item"><div title="Black" className="color-img" style={{ backgroundColor: `rgb(0, 0, 0)` }}></div></div>
                                                    <div className="color-item"><div title="Orrange" className="color-img" style={{ backgroundColor: "orange" }}></div></div>
                                                    <div style={{ clear: "both" }}></div> */}

                                                    <div className="color-item"><div title={curPipingColor.name + " (" + curPipingColor.btnhole_code + ")"} className="color-img" style={{ backgroundColor: curPipingColor.color }}
                                                        onClick={onShowPipingColorSelector}
                                                    ></div></div> &nbsp;
                                                    {curPipingColor.name + " (" + curPipingColor.fabric + ")"}
                                                    <Modal show={showPipingColorSelector} onHide={onPipingColorSelectorClose} dialogClassName="buttonhole-modal">
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Piping Color selector</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <PipingColorSelector selectPipingColor={onSelectPipingColor} />
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={onPipingColorSelectorClose}>
                                                                Close
                                                            </Button>

                                                        </Modal.Footer>
                                                    </Modal>
                                                </div>

                                                {(!(collar.id === "2" || collar.id === "3" || collar.id === "6" || collar.id === "7" || collar.id === "8")) && <>
                                                    <div className="row m-auto">
                                                        <div className="form-check pl-5 mt-3" style={{ whiteSpace: "nowrap" }}>
                                                            <input className="form-check-input" type="checkbox" id="removeableStays"
                                                                name="removeableStays" checked={removeableStay}
                                                                onChange={handleRemoveableStay} />
                                                            <label className="form-check-label"><strong>&nbsp;Removeable stays</strong></label>
                                                        </div>
                                                    </div>
                                                </>}

                                            </div>
                                            <div className='col-md-6 border detail-item d-flex align-items-top'>
                                                <div className='row  '>
                                                    {sleeve.id === "1" && <>
                                                        <div className='col-6 '>

                                                            <div className="form-check p-6 mt-3 " style={{ whiteSpace: "nowrap" }}>
                                                                <strong>Inside cuff: {curInsCuffFabric.fabric_code}</strong>
                                                                <img src={curInsCuffFabric.small_img} className="img-fluid inside-fabric" alt="Cuff"
                                                                    onClick={onSelectInsideCuff}

                                                                />
                                                                <Modal show={showFabricInsCuffSelector} onHide={onSelectorInsCuffClose} dialogClassName="my-modal">
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Inside cuff fabric selector</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <SubsetFabricSelect orderData={fabricTempData} fabricsData={fabricsData} selectFabric={onSelectInsideCuffFabric} />
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="secondary" onClick={onSelectorInsCuffClose}>
                                                                            Close
                                                                        </Button>

                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </div>
                                                            <div className="row m-auto">
                                                                <div className="form-check pl-5 mt-3" style={{ whiteSpace: "nowrap" }}>
                                                                    <input className="form-check-input" type="checkbox" id="leftWristPlus"
                                                                        name="leftWristPlus" checked={leftWristPlus}
                                                                        onChange={handleLeftWristPlus} />
                                                                    <label className="form-check-label"><strong>&nbsp;Left wrist + 1 cm</strong></label>
                                                                </div>

                                                                <div className="form-check pl-5 mt-0" style={{ whiteSpace: "nowrap" }}>
                                                                    <input className="form-check-input" type="checkbox" id="rightWristPlus"
                                                                        name="rightWristPlus" checked={rightWristPlus}
                                                                        onChange={handleRightWristPlus} />
                                                                    <label className="form-check-label"><strong>&nbsp;Right wrist + 1 cm</strong></label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-6'>
                                                            <img src={cuffssleeve.imgDetail} className="img-fluid" alt="No cuff" style={{ width: "100px", height: "100px", display: "block", margin: "20px", span: "20px" }} />
                                                        </div>
                                                    </>}
                                                </div>
                                            </div>
                                        </div>

                                        {embroidery.id === "2" && <>
                                            <hr className="m-2" />
                                            <div className='row'><strong>Embroidery</strong></div>
                                            <div className='row'>
                                                <div className="col">
                                                    <label>Text :</label><br />
                                                    <input type="text" placeholder="Enter text" onChange={handleEmbroideryText} value={embroideryText} />&nbsp;

                                                </div>
                                                <div className="col">
                                                    <label htmlFor="position">Position:</label><br />
                                                    <select name="position" id="position" onChange={handleEmbroideryPosition} className="form-select">
                                                        <option value="1">Chest</option>
                                                        <option value="2">Waist</option>
                                                        <option value="3">Bottom</option>
                                                        <option value="4">Cuff</option>
                                                        <option value="5">Inside collar</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-2">
                                                    <label htmlFor="font">Font:</label><br />
                                                    <select name="font" id="font" onChange={handleEmbroideryFont} className="form-select">
                                                        <option value="1">Modern</option>
                                                        <option value="2">Classic</option>

                                                    </select>
                                                </div>
                                                <div className="col-4">
                                                    {embroideryFont === "1" ?
                                                        <img src='./images/embroidery/modern_font.jpg' className="img-fluid"
                                                            alt="Embroidery sample"
                                                            style={{ width: "150px", display: "block", margin: "20px", span: "20px" }} />

                                                        :
                                                        <img src='./images/embroidery/classic_font.jpg' className="img-fluid"
                                                            alt="Embroidery sample"
                                                            style={{ width: "150px", display: "block", margin: "20px", span: "20px" }} />
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="color">Embroidery Color:</label><br />
                                                    <div className="row">
                                                        <div className="color-item"><div title={embroideryColor.name + " (" + embroideryColor.embcolor_code + ")"} className="color-img" style={{ backgroundColor: embroideryColor.color }}
                                                            onClick={onShowEmbColorSelector}
                                                        ></div></div>&nbsp;

                                                        {embroideryColor.name + " (" + embroideryColor.embcolor_code + ")"}
                                                        <Modal show={showEmbColorSelector} onHide={onEmbColorSelectorClose} dialogClassName="buttonhole-modal">
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Embroidery Color selector</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <EmbroideryColorSelector selectEmbColor={onSelectEmbColor} />
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={onEmbColorSelectorClose}>
                                                                    Close
                                                                </Button>

                                                            </Modal.Footer>
                                                        </Modal>
                                                        <div style={{ clear: "both" }}></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </>}
                                        <hr className="m-2" />
                                        <div className="row">
                                            <label htmlFor="quantity">Quantity :</label><br />
                                            <input id="quantity" type="number" style={{ width: "150px" }} onChange={handleQuantityChange} value={quantity}
                                            // onKeyDown={(event) => {
                                            //     if (!/[0-9]/.test(event.key)) {
                                            //         event.preventDefault();
                                            //     }
                                            // }}
                                            />
                                        </div>
                                        <div className="row">
                                            <label htmlFor="notes">Notes for this shirt:</label>&nbsp;<br />
                                            <textarea id="notes" name="notes" rows="3" cols="4" onChange={handleNotesChange} value={notes} >
                                            </textarea>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col>

                    </Row >

                    <Row style={{ display: "flex", justifyContent: "right" }}>
                        <button type="submit" className="btn btn-primary" style={{ width: "20vw" }}>Confirm shirt configuration</button>

                    </Row>
                </Container >


            </form >
            {/* DEBUG: {JSON.stringify(props.orderData)} */}

        </>
    );
}

export default SelectParts;