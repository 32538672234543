import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';

import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
    randomCreatedDate,
    randomTraderName,
    randomId,
    randomArrayItem,
} from '@mui/x-data-grid-generator';

//const roles = ['Market', 'Finance', 'Development'];
// const randomRole = () => {
//     return randomArrayItem(roles);
// };

const initialRows = [
    {
        id: 1,
        name: "delta_1a",
        collar: "1",
        chest: "8",
        waist: "10",
        hip: "6",
        shoulder: "0",
        upper_arm: "5",
        cuff: "7",
        sleeve_length: "1",
        back_len_business: "1",
        back_len_casual: "-3"
    },
    {
        id: 2,
        name: "delta_1b",
        collar: "1",
        chest: "5",
        waist: "7",
        hip: "3",
        shoulder: "-2",
        upper_arm: "3",
        cuff: "7",
        sleeve_length: "-1",
        back_len_business: "1",
        back_len_casual: "-3"
    },
    {
        id: 3,
        name: "delta_2a",
        collar: "1",
        chest: "12",
        waist: "13",
        hip: "8",
        shoulder: "1",
        upper_arm: "6",
        cuff: "7",
        sleeve_length: "0",
        back_len_business: "1",
        back_len_casual: "-3"
    },
    {
        id: 4,
        name: "delta_2b",
        collar: "1",
        chest: "9",
        waist: "10",
        hip: "5",
        shoulder: "-1",
        upper_arm: "4",
        cuff: "7",
        sleeve_length: "-2",
        back_len_business: "1",
        back_len_casual: "-4"
    },
    {
        id: 5,
        name: "delta_3a",
        collar: "1",
        chest: "16",
        waist: "17",
        hip: "10",
        shoulder: "2",
        upper_arm: "8",
        cuff: "7",
        sleeve_length: "0",
        back_len_business: "1",
        back_len_casual: "-4"
    },
    {
        id: 6,
        name: "delta_3b",
        collar: "1",
        chest: "13",
        waist: "14",
        hip: "7",
        shoulder: "0",
        upper_arm: "6",
        cuff: "7",
        sleeve_length: "-2",
        back_len_business: "1",
        back_len_casual: "-4"
    }

];

function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
        const id = randomId();
        setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    );
}

export default function TailorShopConfig(props) {
    const [rows, setRows] = useState(initialRows);
    const [rowModesModel, setRowModesModel] = useState({});
    const [bypassReviewOrder, setBypassReview] = useState(true);

    const auth = useAuth();

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    // const handleDeleteClick = (id) => () => {
    //     setRows(rows.filter((row) => row.id !== id));
    // };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        { field: 'name', headerName: 'Name', width: 120, editable: false },
        {
            field: 'collar',
            headerName: 'Collar',
            type: 'number',
            width: 90,
            editable: true,
        },
        {
            field: 'chest',
            headerName: 'Chest',
            type: 'number',
            width: 90,
            editable: true,
        },
        {
            field: 'waist',
            headerName: 'Waist',
            width: 90,
            editable: true,
            type: 'number',

        },
        {
            field: 'hip',
            headerName: 'Hips',
            width: 90,
            editable: true,
            type: 'number',

        },
        {
            field: 'shoulder',
            headerName: 'Shoulders',
            width: 90,
            editable: true,
            type: 'number',

        },
        {
            field: 'upper_arm',
            headerName: 'Upper Arm',
            width: 90,
            editable: true,
            type: 'number',

        },
        {
            field: 'cuff',
            headerName: 'Cuff',
            width: 90,
            editable: true,
            type: 'number',

        },
        {
            field: 'sleeve_length',
            headerName: 'Sleeve Length',
            width: 120,
            editable: true,
            type: 'number',

        },
        {
            field: 'back_len_business',
            headerName: 'Back Len Business',
            width: 120,
            editable: true,
            type: 'number',

        },
        {
            field: 'back_len_casual',
            headerName: 'Back Len Casual',
            width: 120,
            editable: true,
            type: 'number',

        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    // <GridActionsCellItem
                    //     icon={<DeleteIcon />}
                    //     label="Delete"
                    //     onClick={handleDeleteClick(id)}
                    //     color="inherit"
                    // />,
                ];
            },
        },
    ];

    const handleUpdate = () => {
        //   console.log(rows);
        props.updateConfig({ rows, bypassReviewOrder });
    }

    const handleBypassReviewChange = (event) => {
        //     alert(event.target.value);

        //  setBypassReview(!bypassReview);
        setBypassReview(event.target.checked);

    }

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        let data = props.tailorshopData.config.deltaset;
        data.forEach((e, idx) => {
            e["id"] = idx;
        });
        setRows(data);
        //   setBypassReview(props.tailorshopData.config.bypassReviewOrder === "true");

    }, []);

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="bypass_review" checked={bypassReviewOrder} onChange={handleBypassReviewChange} />

                <label className="form-check-label" htmlFor="bypass_review">
                    Bypass Order Review
                </label>
            </div>
            <br />
            <label>Shirt Delta Set:</label>
            <p style={{ color: "red" }}>Warning: Change only when you truly understand.</p>
            <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                // slots={{
                //     toolbar: EditToolbar,
                // }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
                pageSizeOptions={[6, 10, 25, 50, 100]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 6, page: 0 },
                    },
                }}
            />

            <button type="submit" className="btn btn-success" onClick={handleUpdate}>Update</button>

        </Box>

    );
}