import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import Button from 'react-bootstrap/Button';
import { useAuth } from "../../hooks/AuthProvider";
import ReCAPTCHA from "react-google-recaptcha";
import './login.css'
import { call_api_key } from '../../global';

/* Tham khao

https://dev.to/miracool/how-to-manage-user-authentication-with-react-js-3ic5 

*/

export default function ForgotPassword(props) {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [ckey, setCKey] = useState("");
    const [error, setError] = useState('');


    const auth = useAuth();

    // console.debug('auth', auth);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (username !== "") {
                fetch("/api/resetpasswrd", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "auth-key": call_api_key,
                    },
                    body: JSON.stringify({ email: username, ckey }),
                })
                    .then((response) => response.json())
                    .then((rowData) => {
                        //        console.log(rowData);
                        alert("The link to reset your password has been sent to your email. Please check your email.");
                        navigate("/");
                    })
                    .catch((error) => { console.log(error); alert("Error call API"); });

                return;
            }
            alert("pleae provide a valid input");

        } catch (err) {
            setError('Invalid username or password');
        }
    };


    function onChangeCaptcha(value) {
        setCKey(value);
    }

    return (
        <>
            <div className="Auth-form-container">

                <form className="Auth-form" onSubmit={handleSubmit}>
                    <div className="Auth-form-content">
                        <div className="leftbox">
                        </div>
                        <div className="rightbox">
                            <h3 className="Auth-form-title">Forgot your password</h3>
                            <div>The link to reset your password will be sent to your email address.</div>
                            <div className="form-group mt-3">
                                <label>Enter email address</label>
                                <input
                                    type="text"
                                    id="username"
                                    value={username}
                                    className="form-control mt-1"
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Enter email address"
                                />
                            </div>

                            <div className="form-group mt-3">
                                <ReCAPTCHA
                                    sitekey="6LdijdQSAAAAAPatYPXoMohy98yDVnEZN0lczAVM"

                                    onChange={onChangeCaptcha}
                                />
                            </div>

                            <div className="d-grid gap-2 mt-3">
                                <button type="submit" className="btn btn-primary">
                                    Renew Password
                                </button>
                            </div>
                            {error && <p>{error}</p>}
                        </div>
                    </div>
                </form>
            </div>

        </>
    );
};


